export const general = {
  academy: 'University',
  accept: 'Accept',
  accuracy: 'Accuracy',
  action_cannot_be_reverted: 'The action cannot be reversed',
  active: 'active',
  add: 'Add',
  add_attachment: 'Add attachment',
  all: 'All',
  and: 'and',
  archive: 'Archive',
  are_you_sure: 'Are you sure?',
  authors: 'Authors',
  avatar: 'Avatar',
  back: 'Back',
  calendar: 'Calendar',
  cancel: 'Cancel',
  change: 'Change',
  change_contrast: 'Change contrast',
  change_order: 'Change order',
  change_to: 'change to',
  check_errors: 'Check errors',
  clear: 'Clear',
  clear_filters: 'Clear filters',
  collapse: 'Hide',
  confirm: 'Confirm',
  content: 'Content',
  copy: 'Copy',
  currency: {
    pln: 'zł'
  },
  create_new: 'Create new',
  date_added: 'Date added',
  date_of_publication: 'Date of publication',
  days_left: 'Left 1 day to the end | Left{count} days to the end ',
  decrease_font: 'Smaller letters',
  delete: 'Delete',
  delete_message: 'Delete message',
  deleted: 'Removed',
  dictionary_updated: 'Dictionary updated sucessfully',
  dictionary_word_updated: 'Word updated successfully',
  discard_changes: 'Reject changes',
  discipline: 'Discipline',
  download: 'Download',
  duplicate: 'Duplicate',
  edit: 'Edit',
  enlarge_font: 'Bigger letters',
  error_handling: 'Error of service',
  error_try_later: 'An unexpected error occured. try again later.',
  exclamation_mark: 'Attention',
  expand: 'Show',
  fields_not_filled: 'Some fields were filled incorrectly. Try agian.',
  forbidden: 'No access.',
  from: 'from',
  full_time: 'Full-time',
  gallery: 'Gallery',
  generate: 'Generate',
  go_down: 'Down',
  go_up: 'Upwards',
  hello_world: 'en Siema Świecie!',
  hide: 'Hide',
  id: 'ID',
  in: 'in',
  information: 'Information',
  lack: 'lack',
  load: 'Load',
  lock: 'Lock',
  logged_out: 'You are logged out',
  main_menu: 'Main menu',
  make_copy: 'Duplicate',
  mark: 'Mark',
  message_sent: 'The message has been sent',
  message_type: {
    communique: 'Notification',
    newsletter: 'Newsletter',
    sms: 'SMS'
  },
  minuses: {
    m1: 'If the scientist who declared discipline and is counted to N number, has not published any publication from journal, monograph or conferences list from 2019 or their publication was not positively evaluated by experts, the number of 3N is decreased by 3.',
    m2: 'If the scientist who declared discipline and has not declared N number statement in any university where their were employed, the number of 3N for the discipline/disciplines is decreased by 6.',
    m3: 'If the scientist who has not declared discipline, the number of 3N for any discipline is decreased by 6.'
  },
  new_message: 'New message',
  no: 'no',
  no_access: 'No access',
  noname: 'Unnamed',
  not_applicable: 'Not applicable',
  number: 'Number',
  or: 'or',
  page: 'Page',
  pagination: 'Pagination',
  per_page: 'Records on page',
  preview: 'Preview',
  preview_saved_draft: 'Draft preview',
  print: 'Print',
  register: 'Register',
  reject: 'Reject',
  reject_changes: 'Reject changes',
  remove: 'Remove',
  remove_from_favorites: 'Remove from favourites',
  results_found: '0 results found | 1 result found | {count} results found',
  roles: {
    manager: 'manager',
    naukowiec: 'scientist',
    pracownik: 'administrative worker',
    pracownikjednostki: 'faculty worker',
    kierownikdyscypliny: 'discipline manager',
    analityknaukowy: 'analyst',
    editor: 'editor',
    dziekan: 'dean',
    superkierownik: 'supermanager',
    useradmin: 'user administrator',
    custom2: 'custom2',
    custom3: 'custom3',
    jednostka: 'faculty',
    dyscyplina: 'discipline',
    rektor: 'rector',
    audyt: 'audit'
  },
  save: 'Save',
  save_changes: 'Save changes',
  save_draft: 'Save draft',
  see_less: 'Show less',
  see_more: 'Show more',
  select: 'Select',
  send: 'Send',
  show_all: 'Show all',
  show_more: 'Show more',
  show_results: 'Show results',
  skip_to_content: 'Skip to content',
  slots: 'Slots',
  statements: 'Statements',
  status: 'Status',
  statuses: {
    accepted: 'Accepted',
    archived: 'Archived',
    draft: 'Draft',
    finished: 'Done',
    for_acceptance: 'Waiting for acceptance',
    recent: 'New',
    rejected: 'Rejected',
    withdrawn: 'Withdrawn'
  },
  update_scopus: 'Update Scopus datas',
  wrong_password: 'Wrong password',
  wrong_combination_of_login: 'Wrong combination of login and password',
  error: 'Error',
  no_rights: 'No rights',
  steps: {
    next: 'Next',
    previous: 'Back',
    publish: 'Save and publish',
    send_for_publication: 'en Save and send to publish'
  },
  success: 'Success',
  suggest: 'Suggest',
  text_section: 'Text section',
  time_to_end: 'Time to end',
  title: 'Title',
  to: 'to',
  type: 'Type',
  unexpected_error: 'An unexpected error occured.',
  unlock: 'Unlock',
  unregister: 'Unsign',
  update: 'Update',
  update_application_text_1: 'The service is being updated. Access to the application is blocked during the update.',
  update_application_text_2: 'The update process should take no more than 15 minutes. We apologize for any inconvenience.',
  when: 'When',
  where: 'Where',
  withdraw: 'Withdraw',
  words: 'Words',
  yes: 'yes'
}
