export const views = {
  admin_dashboard: {
    subtitle: 'Administracja',
    title: 'Pulpit'
  },
  academies: {
    created: 'Uczelnia utworzona',
    droped: 'Uczelnia usunięta',
    list: {
      base_cleaning: 'Czyszczenie bazy',
      base_cleaning_confirmation: 'Czy na pewno chcesz wyczyścić bazę danej uczelni?',
      base_deleting: 'Usuwanie bazy',
      base_deleting_confirmation: 'Czy na pewno chcesz usunąć bazę danej uczelni?',
      base_updating: 'Aktualizacja słowników',
      base_updating_confirmation: 'Czy na pewno chcesz zaktualizować słowniki danej uczelni?',
      cyclical_evaluation: 'Cykliczna ewaluacja',
      go_into_academy: 'Przejdź na uczelnię',
      locked: 'Blokada',
      name: 'Nazwa uczelni',
      new: 'Dodaj uczelnie',
      only_last: 'Tylko ostatnie',
      replication_date: 'Data replikacji',
      subtitle: '',
      title: 'Lista uczelni'
    },
    rights: {
      subtitle: '',
      title: 'Uprawnienia'
    },
    updated: 'Słowniki zostały zaktualizowane'
  },
  accessibility_declaration: {
    title: 'Deklaracja dostępności'
  },
  achievement: {
    mark_as_original: 'Oznacz jako oryginał',
    import_pcg: {
      import: 'Importuj DN PCG'
    },
    list: {
      adding_column: 'Dodawanie kolumny',
      archive: 'Archiwizuj',
      archived: 'Zarchiwizowane',
      archiving: 'Archiwizacja',
      archiving_confirmation: ' | Czy na pewno chcesz zarchiwizować {count} osiągnięcie? | Czy na pewno chcesz zarchiwizować {count} osiągnięcia? | Czy na pewno chcesz zarchiwizować {count} osiągnięć?',
      art_kind: 'Rodzaj artystyczny',
      art_type: 'Typ artystyczny',
      assign_discipline: 'Przypisanie dyscypliny',
      assign_discipline_confirmation: ' | Czy na pewno chcesz przypisać dyscyplinę wiodącą pracownika jako deklarowaną dla osiągnięcia dla {count} osiągnięcia? | Czy na pewno chcesz przypisać dyscyplinę wiodącą pracownika jako deklarowaną dla osiągnięcia dla {count} osiągnięć? | Czy na pewno chcesz przypisać dyscyplinę wiodącą pracownika jako deklarowaną dla osiągnięcia dla {count} osiągnięć?',
      author_unit_tooltip: 'Wskazuje osiągnięcia autorów w kontekście jednostki, która autorzy wybrali dla danego osiągnięcia',
      columns_all: 'Wszystkie',
      columns_publications: 'Publikacje',
      columns_projects: 'Projekty i usługi',
      count_points: 'Przeliczenie punktów',
      count_points_confirmation: ' | Czy na pewno chcesz przeliczyć punkty dla {count} osiągnięcia? | Czy na pewno chcesz przeliczyć punkty dla {count} osiągnięć? | Czy na pewno chcesz przeliczyć punkty dla {count} osiągnięć?',
      available_in: 'Dostępność',
      availability_text: 'Osiągnięcie widoczne w bazach danych',
      by_names: 'Autor / Współautor',
      by_project: 'Powiązane osiągnięcia',
      by_title: 'Wyszukaj w osiągnięciach',
      collective_actions: 'Akcje zbiorcze',
      column_name: 'Nazwa kolumny',
      conference_material: 'Materiał konferencyjny',
      contributors: 'Współautorzy',
      contributors_tooltip: 'Wskazuje czy do osiągnięcia są przypisani autorzy',
      discipline: 'Dyscyplina (ewaluacja osiągnięcia)',
      doctoral_students: 'Doktoranci',
      editing_source_texts: 'Edycja tekstów źródłowych',
      employee_statement_discipline: 'Dyscyplina (oświadczenie)',
      evaluation: 'Zgoda na ewaluację',
      evaluation_confirmation: ' | Czy na pewno chcesz zmienić zgodę na ewaluację dla {count} osiągnięcia? | Czy na pewno chcesz zmienić zgodę na ewaluację dla {count} osiągnięć? | Czy na pewno chcesz zmienić zgodę na ewaluację dla {count} osiągnięć?',
      export_achievements: 'Eksport XLS',
      export_achievements_pdf: 'Eksport PDF',
      export_orcid: 'Eksport ORCID',
      export_pbn_api: 'Eksport PBN API',
      export_polon_api: 'Eksport POL-on API',
      export_academy_reports_success: 'Raport przygotowany poprawnie',
      export_academy_reports_failure: 'Przygotowanie raportu zakończone niepowodzeniem',
      export_achievements_confirmation: ' | Czy na pewno chcesz wyeksportować 1 osiągnięcie? | Czy na pewno chcesz wyeksportować {count} osiągnięcia? | Czy na pewno chcesz wyeksportować {count} osiągnięć?',
      export_achievements_failure: 'Eksport osiągnięć zakończony niepowodzeniem',
      export_achievements_success: 'Eksport osiągnięć zakończony pomyślnie',
      fees: 'Opłaty',
      filter: 'Filtruj',
      filters: 'Zapisane filtry',
      filters_deleting: 'Usuwanie zapisanych filtrów',
      filters_delete_confirmation: 'Czy na pewno chcesz usunąć zapisane filtry?',
      filters_save: 'Zapisz filtry',
      filters_saving: 'Zapisywanie filtrów',
      financing_sources: 'Źródła finansowania',
      generate_pdf: 'Generuj PDF',
      generate_xls: 'Generuj XLS',
      headers_deleting: 'Usuwanie szablonu',
      headers_delete_confirmation: 'Czy na pewno chcesz usunąć szablon?',
      headers_save: 'Zapisz szablon',
      headers_saving: 'Zapisywanie szablonu',
      has_duplicates: 'Posiada duplikaty',
      has_duplicates_tooltip: 'Wskazuje czy osiągnięcie posiada duplikaty (ale samo w sobie nie jest duplikatem)',
      has_fee: 'Ustawiono opłaty',
      hide_filters: 'Ukryj filtry',
      highest_percentile: 'Najwyższy percentyl',
      monograph_type: 'Typ osiągnięcia - Monografia / Redakcja',
      my_publications: 'Moje osiągnięcia',
      new: 'Nowe osiągnięcie',
      no_records: 'Niestety nie odnaleziono osiągnięć spełniających warunki wyszukiwania',
      no_type: 'Nie został wybrany rodzaj nowego osiągnięcia',
      open_access: 'Open Access',
      record: 'Sciencecloud',
      per_page: 'wyników na stronę',
      pub_create_date: 'Data utworzenia',
      pub_date: 'Rok',
      pub_title: 'Tytuł osiągnięcia',
      pub_type: 'Rodzaj osiągnięcia',
      pub_modify_date: 'Data modyfikacji',
      commer_filter: 'Typ osiągnięcia - Usługi / Komercjalizacje',
      publications_review: 'Lista osiągnięć',
      polon: 'POL-on',
      prizes_and_awards: 'Nagrody i wyróżnienia',
      related_project_tooltip: 'Na podstawie tytułu osiągnięcia wskazuje wszystkie osiągnięcia, z którymi jest powiązane wybrane osiągnięcie (np. osiągnięcia zrealizowane w ramach projektu itp.)',
      remove_publication: 'Usuń osiągnięcie',
      report_type: 'Typ raportu',
      repository: 'Repozytorium',
      restore: 'Przywróć',
      restoring: 'Przywracanie',
      restoring_confirmation: ' | Czy na pewno chcesz przywrócić 1 osiągnięcie? | Czy na pewno chcesz przywrócić {count} osiągnięcia? | Czy na pewno chcesz przywrócić {count} osiągnięć?',
      results: '0 wyników | 1 wynik | {count} wyniki | {count} wyników',
      review_article: 'Artykuł recenzyjny',
      selected_elements: 'Zaznaczonych elementów',
      set_status: 'Ustaw status',
      set_status_confirmation: ' | Ustaw status {count} osiągnięcia na: | Ustaw status {count} osiągnięć na:',
      show_as: 'pokaż jako',
      show_as_academy_report: 'Raport uczelni',
      show_as_list: 'Lista',
      show_as_table: 'Tabela',
      show_columns: 'Szablon',
      show_filters: 'Pokaż filtry',
      show_submited: 'Zgoda na ewaluację',
      show_submited_tooltip: 'Wskazuje czy do osiągnięcia jest przypisane co najmniej jedno tzw. III oświadczenie (zgłaszające osiągnięcie do ewaluacji)',
      qualifies_for_eval: 'Podlega ewaluacji',
      qualifies_for_eval_tooltip: 'Wskazuje czy osiągnięcie jest liczone do wyniku dyscypliny i czy osiągnięcie spełnia wymogi ewaluacji',
      sort_by: 'Sortuj po',
      source: 'Źródło',
      source_points: 'Punkty źródła',
      source_points_tooltip: 'Wyświetlane punkty są punktami źródłowymi przypisanymi do czasopisma, wydawnictwa lub konferencji na listach ministerialnych. Wyliczenia dla pracowników mogą się różnić w związku z zastosowaniem dodatkowych kryteriów ewaluacyjnych.',
      source_text: 'Osiągnięcie zostało pobrane z bazy danych',
      status: 'Status',
      status_pbn: 'Status POL-on/PBN',
      subtitle: 'Ewidencja',
      summary: 'Podsumowanie',
      title: 'Osiągnięcia',
      translation_other: 'Przekład na inny język nowożytny',
      translation_pl: 'Przekład na język polski',
      type_of_achievement: 'Rodzaj osiągnięcia',
      user_publications: 'Osiągnięcia użytkownika',
      web_of_science_tooltip: 'Wskazuje czy do osiągnięcie było indeksowane w bazie Web of Science. Dotyczy osiągnięć opublikowanych w latach 2017-2018',
      wos_scopus_tooltip: 'Wskazuje czy do osiągnięcie było indeksowane w bazie Web of Science lub Scopus. Dotyczy osiągnięć opublikowanych w latach 2017-2018',
      unit: 'Rola autora',
      unit_tooltip: 'Wskazuje osiągnięcia autorów jednostki, do której ci autorzy są przypisani jako do głównego miejsca pracy',
      your_publications: 'Twoje osiągnięcia'
    },
    new: {
      additional: 'Dodatkowe',
      select_month: 'Wybierz miesiąc',
      select_year: 'Wybierz rok',
      add_attachment_file: 'Dodaj załącznik',
      add_bibliography: 'Dodaj opis bibliograficzny',
      add_evidence: 'Dodaj charakterystykę dowodu wpływu',
      add_evidence_file: 'Dodaj dowód wpływu',
      add_file_description: 'Dodaj opis pliku',
      add_link_description: 'Dodaj opis odnośnika',
      add_publication_link: 'Dodaj odnośnik',
      art_characteristic: 'Charakterystyka osiągnięcia artystycznego',
      art_place_of_distribution: 'Miejsce rozpowszechnienia',
      art_place_of_premiere: 'Miejsce premiery',
      added_document: 'Dodawane osiągnięcie',
      edited_document: 'Edytowane osiągnięcie',
      share: 'Udział',
      book_project_fnp: 'Monografia powstała w wyniku realizacji projektu finansowanego przez FNP',
      book_project_nprh: 'Monografia powstała w ramach programu NPRH',
      book_project_ue: 'Monografia powstała w ramach programu ramowego Unii Europejskie',
      book_project_ncn: 'Monografia powstała w wyniku realizacji projektu finansowanego przez NCN',
      basic: {
        abstract: 'Abstrakt',
        add_abstract: 'Dodaj abstrakt',
        add_plant_variety: 'Dodaj nazwę odmiany',
        add_title_patent: 'Dodaj tytuł wynalazku',
        add_title_plant: 'Dodaj nazwę gatunku',
        add_title_utility: 'Dodaj tytuł wzoru użytkowego',
        add_title: 'Dodaj tytuł',
        add_word: 'Dodaj nowe słowo kluczowe',
        artistic_title: 'Tytuł (nazwa/tytuł/rola)',
        artistic_title_tooltip: 'Wymienionych w nawiasie elementów można używać w zależności od potrzeb warunkowanych charakterystyką osiągnięcia, np. nazwa i tytuł, nazwa i rola, jak również pojedynczo – nazwa lub tytuł.\nPowiązanie elementów nazwa/tytuł/rola tworzy unikatową nazwę osiągnięcia w ramach poszczególnych typów.',
        distribution_places_button: 'Dodaj nowe miejsce rozpowszechnienia',
        distribution_places_search: 'Wyszukaj w miejscach rozpowszechniania',
        en_abstract: 'Abstrakt angielski',
        en_title: 'Tytuł angielski',
        features: 'Cechy osiągnięcia',
        financing_source_button: 'Dodaj nowe źródło finansowania',
        financing_source_search: 'Wyszukaj w źródłach finansowania',
        flags: 'Flagi',
        flags_button: 'Dodaj nową flagę',
        flags_search: 'Wyszukaj we flagach',
        foreign_funds: 'w tym środki zagraniczne (PLN)',
        foreign_funds_tooltip: 'Wartość wyliczana jako suma środków zagranicznych przyznana przez instytucje finansujące w ramach wprowadzonego projektu oraz projektów powiązanych.',
        national_funds: 'w tym środki krajowe (PLN)',
        national_funds_tooltip: 'Wartość wyliczana jako suma środków krajowych przyznana przez instytucje finansujące w ramach wprowadzonego projektu oraz projektów powiązanych.',
        patent_abstract: 'Streszczenie opisu wynalazku',
        project_abstract: 'Streszczenie projektu w języku polskim',
        project_abstract_en: 'Streszczenie projektu w języku angielskim',
        project_acronym: 'Akronim projektu',
        project_number: 'Numer projektu',
        project_number_tooltip: 'Zalecane wprowadzenie numeru umowy',
        project_title: 'Tytuł projektu w języku polskim',
        project_title_en: 'Tytuł projektu w języku angielskim',
        remove_abstract: 'Usuń abstrakt',
        remove_plant_variety: 'Usuń nazwę odmiany',
        remove_title: 'Usuń tytuł',
        quality_scientific_activity: 'Uwzględnione w ewaluacji jakości działalności naukowej',
        service_title: 'Tytuł, z którego przychód został uzyskany',
        social_abstract: 'Streszczenie opisu wpływu',
        social_abstract_tooltip: 'Streszczenie w języku polskim i angielskim (Maksymalnie 1000 znaków ze spacjami)',
        social_title: 'Tytuł opisu wpływu',
        social_title_tooltip: 'Tytuł w języku polskim i angielskim (Maksymalnie 150 znaków ze spacjami)',
        summary: 'Streszczenie',
        title: 'Tytuł publikacji',
        title_invention: 'Tytuł wynalazku',
        title_model: 'Tytuł wzoru użytkowego',
        title_species: 'Nazwa gatunku',
        total_funds: 'Wysokość środków finansowych ogółem przyznanych na realizację projektu (PLN)',
        total_funds_tooltip: 'Wartość wyliczana jako suma środków ogółem przyznana przez instytucje finansujące w ramach wprowadzonego projektu oraz projektów powiązanych.',
        utility_abstract: 'Streszczenie opisu wzoru użytkowego',
        words: 'Słowa kluczowe',
        words_button: 'Dodaj nowe słowo kluczowe',
        words_search: 'Wyszukaj w słowach kluczowych'
      },
      basic_description: 'Opis podstawowy',
      bibliographic_description: 'Opis bibliograficzny i streszczenie osiągnięć',
      bibliographic_description_tooltip: 'Opis bibliograficzny i streszczenie nie więcej niż 5 osiągnięć naukowych, o których mowa w § 2 ust. 10 pkt 4–6, będących rezultatem badań naukowych lub prac rozwojowych, prowadzonych w okresie objętym ewaluacją jakości działalności naukowej albo przed tym okresem, ale zakończonych nie wcześniej niż w dwudziestym roku poprzedzającym pierwszy rok objęty ewaluacją jakości działalności naukowej, jeżeli dowody tej działalności powstały w okresie objętym ewaluacją, ze wskazaniem głównych autorów tych osiągnięć, którzy są lub byli zatrudnieni w podmiocie albo odbywali kształcenie w podmiocie. Opis i streszczenie może uwzględniać adres strony internetowej, pod którym w Systemie POL-on jest dostępne dane osiągnięcie (maksymalnie 500 znaków ze spacjami dla każdej wersji językowej); opis nie więcej niż 5 osiągnięć artystycznych, o których mowa w § 2 ust. 10 pkt 7 (maksymalnie 600 znaków ze spacjami na każde osiągnięcie dla każdej wersji językowej); opis bibliograficzny i streszczenie osiągnięcia naukowego lub opis osiągnięcia artystycznego mogą uwzględniać adres strony internetowej, pod którym w dniu umieszczenia opisu wpływu w Systemie POL-on jest dostępne dane osiągnięcie',
      commercialization: 'Komercjalizacja wyników badań',
      conference_start_date_error: 'Data rozpoczęcia konferencji jest późniejsza niż data zakończenia',
      continue_editing: 'Pozostań w edycji formularza',
      countries: {
        key: 'Skrót',
        name: 'Nazwa',
        name_en: 'Nazwa angielska'
      },
      data_on_entities: 'Dane o podmiotach realizujących projekt',
      details: {
        achievement_title_error: 'Podaj tytuł osiągnięcia',
        achievement_year: 'Rok osiągnięcia',
        achievement_year_error: 'Podaj rok osiągnięcia',
        achievement_year_tooltip: 'Rok osiągnięcia z punktu widzenia okresu objętego ewaluacją. \n' +
          'Wartość do podglądu, uzupełniana automatycznie na podstawie roku pierwszego upublicznienia lub na podstawie roku realizacji w przypadku braku informacji o roku pierwszego upublicznienia\n' +
          'Wartość porównywana w filtrze lat określonym w wyszukiwarce osiągnięć.',
        add_entity_obtained_patent: 'Dodaj podmiot uprawniony',
        announcement_date: 'Data ogłoszenia',
        announcement_date_plant: 'Data przyznania',
        announcement_date_plant_tooltip: 'Data przyznania wyłącznego prawa do odmiany roślin',
        announcement_date_tooltip: 'Data ogłoszenia w „Wiadomościach Urzędu Patentowego” lub równoważnym biuletynie za granicą',
        another_project_contest_name: 'Inna nazwa konkursu, programu lub przedsięwzięcia',
        another_project_contest_name_tooltip: 'Pole wypełniamy w przypadku gdy Nazwa konkursu, programu lub przedsięwzięcia jest równa inna.',
        apartment_number: 'Numer lokalu',
        application_date: 'Data zgłoszenia',
        application_date_tooltip: 'Data zgłoszenia w Urzędzie Patentowym RP lub we właściwym urzędzie za granicą',
        art_importance: 'Znaczenia osiągnięcia',
        book_with_chapters: 'Monografia z oznaczonym autorstwem rozdziałów',
        botanic_name: 'Nazwa botaniczna',
        building_number: 'Numer budynku',
        city: 'Miejscowość',
        commercialization_type: 'Rodzaj danych finansowych',
        conference_edition_name: 'Nazwa edycji konferencji',
        conference_edition_name_short: 'Nazwa skrócona edycji konferencji',
        conference_edition_external_link: 'Strona internetowa edycji konferencji',
        conference_edition_pbn_id: 'Identyfikator PBN edycji konferencji',
        conference_material: 'Materiał konferencyjny',
        conference_series_external_link: 'Strona internetowa serii konferencji',
        conference_series_name_short: 'Nazwa skrócona serii konferencji',
        contract_date: 'Data przyznania',
        contract_date_tooltip: 'Data zawarcia umowy lub wydania decyzji przez instytucję finansującą.',
        copyright: 'Otwarta licencja',
        created_as_grant: 'Powstała w ramach grantu',
        created_entity_name: 'Nazwa utworzonego podmiotu',
        country_first_public: 'Kraj pierwszego upublicznienia',
        date_patent_translation: 'Data złożenia tłumaczenia',
        date_patent_translation_tooltip: 'Data złożenia tłumaczenia na język polski patentu europejskiego, w którym RP została wyznaczona jako kraj ochrony',
        delivered: 'Pozytywna ocena ekspercka',
        disseminating_science: 'Upowszechnianie nauki',
        evaluation_order: 'Kolejność uwzględnienia w ewaluacji',
        evaluation_order_tooltip: 'Kolejność oceny opisu wpływu przez ekspertów w ewaluacji',
        evaluation_year: 'Rok ewaluacji',
        fees: 'Opłaty za publikacje',
        fees_tooltip: 'Zgodnie z § 2 ust. 10 pkt 4 lit. n i pkt 5 lit. n rozporządzenia Ministra Nauki i Szkolnictwa Wyższego z dnia 6 marca 2019 r. w sprawie danych przetwarzanych w Zintegrowanym Systemie Informacji o Szkolnictwie Wyższym i Nauce POL-on, zwanego dalej „rozporządzeniem”, w wykazie pracowników zamieszcza się: w zakresie informacji o artykułach naukowych - wysokość opłat za opublikowanie artykułu naukowego poniesionych przez podmiot, o którym mowa w lit. d, (wyrażoną w złotych) oraz źródło ich finansowania (środki finansowe, o których mowa w art. 365 pkt 2 ustawy, środki finansowe przyznane na realizację projektu w zakresie badań naukowych lub prac rozwojowych, inne), natomiast w zakresie informacji o monografiach naukowych i redakcji naukowej monografii - wysokość opłat za wydanie monografii naukowej poniesionych przez podmiot, o którym mowa w lit. i, (wyrażoną w złotych) oraz źródło ich finansowania (środki finansowe, o których mowa w art. 365 pkt 2 ustawy, środki finansowe przyznane na realizację projektu w zakresie badań naukowych lub prac rozwojowych, inne). Zgodnie z § 9 ust. 3 ww. rozporządzenia, informacje o wysokości opłat za opublikowanie artykułu naukowego oraz wydanie monografii wprowadza się w sposób ciągły w terminie do 31 grudnia roku następującego po roku zaistnienia zmiany z zastrzeżeniem § 2 rozporządzenia Ministra Edukacji i Nauki z dnia 17 grudnia 2021 r. zmieniającego rozporządzenie w sprawie danych przetwarzanych w Zintegrowanym Systemie Informacji o Szkolnictwie Wyższym i Nauce POL-on, który wskazuje, że dane, o których mowa w § 2 ust. 10 pkt 4 lit. n i pkt 5 lit. n rozporządzenia zmienianego, uzupełnia się w odniesieniu do artykułów naukowych opublikowanych i monografii naukowych wydanych w latach 2020-2021 w terminie do dnia 31 grudnia 2022 r.',
        fee_act: 'Środki finansowe, o których mowa w art. 365 pkt. 2 ustawy',
        fee_act_tooltip: 'Zgodnie z art. 365 pkt 2 ustawy z dnia 20 lipca 2018 r. – Prawo o szkolnictwie wyższym i nauce środki finansowe na szkolnictwo wyższe i naukę przeznacza się na utrzymanie i rozwój potencjału badawczego, w tym:\n' +
          'a) prowadzenie działalności naukowej,\n' +
          'b) realizację inwestycji w obszarze działalności badawczej,\n' +
          'c) kształcenie w szkole doktorskiej,\n' +
          'd) komercjalizację wyników działalności naukowej oraz know-how związanego z tymi wynikami,\n' +
          'e) program „Inicjatywa doskonałości - uczelnia badawcza”,\n' +
          'f) program „Regionalna inicjatywa doskonałości”,\n' +
          'g) rozwój zawodowy pracowników naukowych w instytutach PAN, instytutach badawczych oraz instytutach międzynarodowych.',
        fee_amount: 'Kwota',
        fee_amount_tooltip: 'Należy sprawozdawać wysokość opłat poniesionych na rzecz wydawnictwa zewnętrznego (w szczególności opłaty article processing charge - APC lub submission fee) w złotych w ujęciu brutto.',
        fee_free: 'Publikacja bezkosztowa',
        fee_free_tooltip: 'Wydanie publikacji nie wiązało się z opłatami na rzecz wydawnictwa zewnętrznego lub publikacja wydana przez wydawnictwo należące do podmiotu.',
        fee_other: 'Inne',
        fee_project: 'Środki finansowe przyznane na realizację projektu w zakresie badań naukowych lub prac rozwojowych',
        fee_project_tooltip: 'Wysokość opłat za wydanie publikacji poniesionych ze środków finansowych przyznanych na realizację projektów obejmujących badania naukowe, prace rozwojowe lub upowszechnianie nauki, finansowanych z programów i przedsięwzięć ustanawianych przez ministra, stypendiów i nagród ministra, zadań finansowych z udziałem środków pochodzących z budżetu Unii Europejskiej albo z niepodlegających zwrotowi środków z pomocy udzielanej przez państwa członkowskie Europejskiego Porozumienia o Wolnym Handlu (EFTA), albo z innych środków pochodzących ze źródeł zagranicznych niepodlegających zwrotowi oraz przez instytucje zagraniczne lub organizacje międzynarodowe.',
        financing_institution_different_name: 'Inna nazwa instytucji',
        financing_institution_error_date: 'Podaj datę przyznania',
        financing_institution_error_name: 'Podaj nazwę instytucji',
        financing_institution_kind: 'Rodzaj instytucji finansującej',
        financing_institution_name: 'Nazwa instytucji',
        financing_institution_short: 'Skrót instytucji',
        financing_source: 'Źródło finansowania',
        financing_source_different_name: 'Inne źródło finansowania',
        funds: {
          foreign_funds: 'Środki zagraniczne (PLN)',
          national_funds: 'Środki krajowe (PLN)',
          total_funds: 'Środki ogółem przyznane podmiotowi na realizację projektu (PLN)'
        },
        edition: 'Wydanie',
        edition_in_project: 'Powstała w wyniku realizacji projektu',
        edition_in_project_tooltip: 'Publikacja powstała w wyniku realizacji projektu finansowanego:\n\na) przez Narodowe Centrum Nauki,\nb) przez Fundację na rzecz Nauki Polskiej,\nc) w ramach Narodowego Programu Rozwoju Humanistyki,\nd) w ramach programu ramowego w zakresie wspierania badań i innowacji Unii Europejskiej albo w ramach programu związanego z wdrażaniem takiego programu.',
        entity_granting_patent: 'Nazwa podmiotu przydzielającego patent',
        entity_granting_patent_plant: 'Nazwa podmiotu udzielającego wyłączne prawo',
        entity_granting_patent_utility: 'Nazwa podmiotu udzielającego ochronę',
        entity_leadership: 'Lider',
        entity_leadership_warning: 'Tylko jeden podmiot realizujący projekt może być liderem.',
        entity_name: 'Nazwa podmiotu',
        entity_obtained_patent: 'Podmiot uprawniony',
        entity_obtained_patent_education: 'Instytucja szkolnictwa wyższego i nauki',
        entity_obtained_patent_education_name: 'Nazwa instytucji szkolnictwa wyższego i nauki',
        entity_obtained_patent_name: 'Nazwa podmiotu',
        exhibition_designation: 'Oznaczenie wystawy',
        external_identifier: 'Identyfikator zewnętrzny',
        external_link: 'Adres strony internetowej',
        external_link_tooltip: 'Link do pełnego tekstu lub do strony z informacjami o publikacji\nAdres strony należy wprowadzić poprzedzając go protokołem http lub https',
        financing_institution_country: 'Kraj',
        financing_institution_voivodeship: 'Województwo',
        first_application: 'Czy patent obejmuje uprzednie pierwszeństwo',
        foreign_funds: 'Środki zagraniczne (PLN)',
        implementation_country: 'Kraj realizacji',
        implementation_year: 'Rok realizacji',
        implementation_year_error: 'Niepoprawny rok realizacji',
        implementation_year_pair_error: 'Musi być wypełniona para rok i kraj realizacji',
        important_place: 'Prezentacja w miejscu o szczególnym znaczeniu dla kultury',
        important_publisher: 'Wydanie przez wydawnictwo o międzynarodowej renomie w środowisku artystycznym',
        income_by_entity: 'Czy przychód został uzyskany przez podmiot utworzony przez ten podmiot w celu komercjalizacji?',
        institution_domestic: 'krajowa',
        institution_foreign: 'zagraniczna',
        institution_international: 'międzynarodowa',
        internal_identifier: 'Identyfikator wewnętrzny',
        issue: 'Numer zeszytu',
        monograph_delivered: 'Pozytywna ocena ekspercka monografii',
        name: 'Nazwa konferencji',
        name_short: 'Nazwa konferencji - skrót',
        national_funds: 'Środki krajowe (PLN)',
        number: 'Numer',
        number_of_months: 'Liczba miesięcy',
        number_of_months_error: 'Wymagane jest podanie liczby miesięcy',
        number_of_months_tooltip: 'Ilość miesięcy, które upłynęły od momentu opublikowania do momentu udostępnienia',
        oecd_country: 'Kraj',
        open_access: 'Open Access',
        other_entity: 'Przyznany innemu podmiotowi niż ewaluowany',
        own_funds: 'Wysokość wkładu własnego',
        pages: 'Liczba stron',
        pages_from_to: 'Strony od-do',
        patent_application_number: 'Numer zgłoszenia',
        patent_application_number_in_uprp: 'Numer zgłoszenia w UPRP',
        patent_application_number_tooltip: 'Numer zgłoszenia w Urzędzie Patentowym RP lub we właściwym urzędzie za granicą. Zaleca się podanie samych cyfr z numeru zgłoszenia',
        patent_countries: 'Państwa, na terytorium których uzyskano ochronę',
        patent_number: 'Numer patentu',
        patent_number_tooltip: 'Zaleca się podanie samych cyfr',
        patent_number_plant: 'Numer w księdze ochrony wyłącznej',
        patent_number_plant_tooltip: 'Numer w księdze ochrony prowadzonej przez COBORU lub równoważny urząd z zagranicy, w szczególności CPVO',
        patent_number_utility: 'Numer prawa ochronnego',
        patent_office: 'Zgłoszony w Urzędzie Patentowym RP',
        patent_type: 'Rodzaj ochrony',
        periodical_external_link: 'Strona internetowa czasopisma',
        plant_variety: 'Nazwa odmiany',
        post_conference_volume: 'Tom pokonferencyjny',
        post_conference_volumes: 'Tomy pokonferencyjne',
        post_conference_volume_tooltip: 'Opcję należy zaznaczyć jeżeli artykuł został opublikowany jako rozdział w publikacji, będącej materiałem pokonferenyjnym.',
        postal_code: 'Kod pocztowy',
        principal_name: 'Podmiot zlecający',
        prior_priority_country: 'Kraj uprzedniego pierwszeństwa',
        prior_priority_date: 'Data uprzedniego pierwszeństwa',
        priority_number: 'Numer uprzedniego zgłoszenia',
        project_contest_edition: 'Edycja konkursu, programu lub przedsięwzięcia',
        project_contest_name: 'Nazwa konkursu, programu lub przedsięwzięcia',
        project_priority_name: 'Nazwa działania lub priorytetu',
        publication_date: 'Data publikacji',
        publication_link_error: 'Odnośnik nie może być pusty',
        publication_place: 'Miejsce wydania',
        received_funds: 'Środki finansowe ogółem przyznane przez instytucję finansującą (PLN)',
        received_funds_warning: 'Pole \'Środki finansowe ogółem przyznane przez instytucję finansującą (PLN)\' musi być równe sumie wartości pól: \'Środki krajowe (PLN)\' i \'Srodki zagraniczne (PLN)\'.',
        release_date: 'Data premiery',
        review_article: 'Artykuł recenzyjny',
        scientific_edition: 'Edycja naukowa tekstu źródłowego',
        service_amount: 'Wysokość przychodu',
        service_entity: 'Nazwa podmiotu utworzonego w celu komercjalizacji',
        service_year: 'Rok uzyskania przychodu',
        settlement_date: 'Data rozliczenia środków',
        sharing_date: 'Data udostępnienia',
        sharing_way: 'Sposób udostępnienia',
        sharing_moment: 'Czas udostępnienia',
        street: 'Ulica',
        text_version: 'Wersja tekstu',
        url_address: 'www',
        volume: 'Numer tomu',
        web_of_science: 'Web of Science',
        web_of_science_tooltip: 'Zaznaczony, jeżeli materiał konferencyjny był opublikowany w ostatecznej formie w recenzowanych materiałach z międzynarodowej konferencji naukowej uwzględnionej w roku publikacji w bazie czasopism i abstraktów Web of Sciecne Core Collection. Pole powstało w związku z art. 324 ust 2b przepisów wprowadzających i będzie brane pod uwagę tylko dla publikacji z lat 2017-2018.',
        wos_scopus: 'WoS/SCOPUS',
        wos_scopus_tooltip: 'Zaznaczony, jeżeli czasopismo, z którego pochodzi artykuł, było ujęte w roku opublikowania artykułu jednej z baz: Scopus (czasopismo aktywne), Science CI Expanded, Social Sicences CI, Arts&Humanities CI, Emerging Sources CI. Pole powstało w związku z art. 324 ust 2a Ustawy o Sieci Badawczej Łukasiewicz i będzie brane pod uwagę tylko dla publikacji z lat 2017-2018.',
        year: 'Rok',
        year_first_public: 'Rok pierwszego upublicznienia',
        year_first_public_error: 'Niepoprawny rok pierwszego upublicznienia',
        year_first_public_pair_error: 'Musi być wypełniona para rok i kraj pierwszego upublicznienia'
      },
      discipline_max_percentage: 'Suma udziałów wszystkich dyscyplin musi być równa 100%',
      discipline_percentage: 'Suma udziałów dyscyplin wynosi ponad 100%',
      edit_entity: 'Edytuj podmiot',
      entity_add: '+ Dodaj podmiot',
      entity_added: 'Podmiot już jest na liście',
      entity_no_name: 'Podmiot nie ma nazwy',
      environment_impact: 'Wpływ na otoczenie społeczno-gospodarcze',
      evidence_of_social_impact: 'Dowody wpływu społecznego',
      evidence_of_scientific_activity: 'Dowody wkładu działaności naukowej',
      expert_points: 'Punkty w ocenie eksperckiej',
      expert_points_tooltip: 'Liczba punktów uzyskana przez osiągnięcie w wyniku oceny eksperckiej podczas ewaluacji.',
      external_link_error: 'Niepoprawny format adresu strony internetowej',
      files: 'Pliki i odnośniki',
      files_header: 'Pliki',
      file_description: 'Opis pliku',
      financing_institutions: 'Instytucje finansujące',
      financing_institutions_tooltip: 'Należy wykazać informacje finansowe zawierające się w okresie ewaluacji (wg daty umowy lub decyzji)',
      financing_institution_add: '+ Dodaj instytucję finansującą',
      financing_institution_remove: 'Usuń ostatnią instytucję',
      full_date: 'Pełna data',
      fullfilment: 'Stopień wypełnienia formularza',
      funded_competition_mode: 'Finansowany lub współfinansowany w trybie konkursowym',
      impact_basic_informations: 'Informacje podstawowe',
      impact_characteristics: 'Charakterystyka wpływu',
      impact_characteristics_tooltip: 'Charakterystyka wpływu działalności naukowej (w języku polskim i angielskim), w tym której wyniki są przedmiotem komercjalizacji, ze wskazaniem związku między działalnością naukową a tym wpływem oraz grupy społecznej będącej beneficjentem tego wpływu i obszaru, na który działalność naukowa ma największy wpływ (maksymalnie 5000 znaków ze spacjami dla każdej wersji językowej)',
      impact_description_justification: 'Uzasadnienie oceny opisu wpływu',
      impact_description_justification_tooltip: 'Treść uzasadnienia oceny opisu wpływu, sporządzonego przez eksperta wiodącego podczas ewaluacji.',
      impact_evidence_characterization: 'Charakterystyka dowodów wpływu',
      impact_evidence_characterization_tooltip: 'Szczegółowa charakterystyka nie więcej niż 5 dowodów wpływu działalności naukowej w języku polskim i angielskim (maksymalnie 500 znaków ze spacjami na każdy dowód dla każdej wersji językowej), a w przypadku opisu, o którym mowa w lit. e tiret czwarte, nie więcej niż 5 dowodów wpływu będących wynikiem działalności naukowej innego podmiotu utworzonego przez podmiot w celu komercjalizacji (charakterystyka może uwzględniać adres strony internetowej, pod którym w dniu umieszczenia opisu wpływu w Systemie POL-on jest dostępny dany dowód wpływu)',
      impact_notification_type: 'Rodzaj zgłoszenia wpływu',
      interdisciplinarity: 'Interdyscyplinarność',
      interdisciplinarity_tooltip: 'Należy zaznaczyć w przypadku, gdy wpływ powstał w wyniku interdyscyplinarnych badań naukowych lub prac rozwojowych',
      link_description: 'Opis odnośnika',
      links_header: 'Odnośniki',
      main_conclusions_characteristic: 'Charakterystyka głównych wniosków',
      main_conclusions_characteristic_tooltip: 'Charakterystyka głównych wniosków z badań naukowych lub prac rozwojowych albo efektów działalności naukowej w zakresie twórczości artystycznej w języku polskim i angielskim (maksymalnie 2500 znaków ze spacjami dla każdej wersji językowej)',
      manager_date_error: 'Data rozpoczęcia realizacji projektu jest późniejsza niż data zakończenia realizacji projektu',
      manager_end_date_error: 'Data zakończenia jest późniejsza niż data zakończenia realizacji projektu',
      manager_from_error: 'Nie podano daty rozpoczęcia realizacji projektu',
      manager_name_error: 'Nie podano kierownika projektu',
      manager_project_end: 'Data zakończenia',
      manager_project_start: 'Data rozpoczęcia',
      manager_role_error: 'Nie podano roli kierownika projektu',
      manager_start_date_error: 'Data rozpoczęcia jest wcześniejsza niż data rozpoczęcia projektu',
      manager_to_error: 'Nie podano daty zakończenia realizacji projektu',
      monograph_title: 'Tytuł monografii',
      monograph_not_exist: 'Nie znaleziono takiej monografii w bazie',
      not_in_project: 'Monografia nie powstała w wyniku realizacji projektu',
      no_commercialization_type: 'Musisz wybrać rodzaj danych finansowych',
      no_discipline: 'Nie wybrano dyscypliny',
      no_external_link: 'Musisz podać DOI lub adres strony internetowej',
      no_project_year: 'Podaj datę rozpoczęcia projektu lub datę przyznania środków finansowych przez instytucję finansującą',
      translated_monograph_title: 'Tytuł tłumaczenia monografii',
      open_access: 'Open Access',
      project_basic_informations: 'Dane projektu',
      project_end: 'Data zakończenia realizacji projektu',
      project_entities: 'Podmioty realizujące projekt',
      project_financing_institutions: 'Instytucje finansujące',
      project_implementing_institutions: 'Podmioty realizujące projekt',
      project_individual: 'Projekt prowadzony samodzielnie',
      project_manager: 'Kierownik',
      project_managers: 'Kierownicy',
      project_manager_add: '+ Dodaj kierownika',
      project_participant: 'Uczestnik projektu',
      project_participants: 'Uczestnicy projektu',
      project_participant_add: 'Dodaj uczestnika projektu',
      project_start: 'Data rozpoczęcia realizacji projektu',
      project_type: 'Typ projektu',
      pub: {
        abstract_language_duplicate: 'Abstrakt w takim języku już istnieje',
        add_redactor: '+ Dodaj redaktora',
        add_author: '+ Dodaj autora',
        add_award: '+ Dodaj nagrodę',
        add_award_winner: '+ Dodaj laureata nagrody',
        adding_award_winner: 'Dodawanie laureata nagrody',
        add_collection: '+ Dodaj kolekcję',
        add_discipline: '+ Dodaj dyscyplinę',
        artistic_discipline: 'Dyscyplina artystyczna',
        artistic_kind: 'Rodzaj osiągnięcia',
        artistic_type: 'Typ osiągnięcia',
        authors: 'Autorzy',
        awards: 'Nagrody i wyróżnienia',
        award_country: 'Kraj, w którym przyznano nagrodę lub wyróżnienie',
        award_entity: 'Podmiot przyznający',
        award_features: 'Charakterystyka nagrody lub wyróżnienia',
        award_winner: 'Laureat nagrody',
        award_winners: 'Laureaci nagrody',
        award_winners_remove: 'Usuń laureata nagrody',
        award_winners_tooltip: 'Wskazanie autorów osiągnięcia, którzy otrzymali nagrodę.\n' +
          'Brak określenia konkretnych osób jest jednoznaczny z faktem, iż wszyscy autorzy otrzymali nagrodę.',
        award_year: 'Rok przyznania',
        check_other: 'Wybierz inną',
        city: 'Miejscowość',
        collection: 'Prestiżowe kolekcje i miejsca o szczególnym znaczeniu dla kultury',
        collection_country: 'Kraj, w którym znajduje się kolekcja lub miejsce o szczególnym znaczeniu dla kultury',
        collection_name: 'Nazwa kolekcji lub miejsca o szczególnym znaczeniu dla kultury',
        collections: 'Kolekcje',
        conference_from: 'Data rozpoczęcia',
        conference_to: 'Data zakończenia',
        confirm_duplicate_confirmation: 'Chcesz kontynuuować zapis osiągnięcia?',
        confirm_duplicate_information: 'Dla obecnych danych osiągnięcie zostanie utworzone jako duplikat do już istniejącego osiągnięcia',
        contest_name: 'Nazwa konkursu',
        country: 'Kraj',
        discipline: 'Dyscyplina',
        disciplines: 'Dyscypliny',
        distribution_place_exist: 'Miejsce rozpowszechnienia już istnieje',
        entity: 'Podmiot',
        financing_source_error: 'Nie podano źródła finansowania',
        importance: 'Kategoria osiągnięcia',
        issue_number: 'Numer wydania',
        kind: 'Rodzaj',
        language: 'Język',
        language_error: 'Nie podano języka',
        main_language: 'Główny język publikacji',
        name: 'Nazwa',
        names: 'Imię i nazwisko',
        original_authors: 'Autorzy oryginału',
        original_authors_tooltip: 'W przypadku większej ilości autorów oddzielamy poszczególnych autorów średnikiem',
        original_language: 'Język oryginału',
        periodical: 'Czasopismo',
        plant_variety_duplicate: 'Nazwa odmiany w takim języku już istnieje',
        publishing_series: 'Seria wydawnicza',
        publishing_series_number: 'Numer w serii wydawniczej',
        redactors: 'Redaktorzy',
        related_achievements: 'Powiązane osiągnięcia',
        related_grants: 'Powiązane projekty',
        related_projects: 'Projekty współfinansowane w ramach osobnej umowy',
        role: 'Rola',
        search_related_achievement: 'Wyszukaj osiągnięcie',
        search_related_projects: 'Wyszukaj projekt współfinansowany w ramach osobnej umowy',
        search_related_grant: 'Wyszukaj projekt',
        social_points_tooltip: 'Całkowita liczba punktów za osiągnięcie, wyliczana na podstawie zasięgu, znaczenia wpływu oraz interdyscyplinarności',
        title_language_duplicate: 'Tytuł w takim języku już istnieje',
        word: 'Słowo',
        word_exist_error: 'Słowo już istnieje'
      },
      publication: 'Osiągnięcie',
      publication_details: 'Szczegóły osiągnięcia',
      publication_link: 'Odnośnik',
      publication_type: 'Typ osiągnięcia',
      range: 'Zasięg',
      range_artistic: 'Zakres rozpowszechnienia',
      rationale_for_interdisciplinarities: 'Charakterystyka interdyscyplinarności',
      rationale_for_interdisciplinarities_tooltip: 'Charakterystyka w jezyku polskim i angielskim, znaczenia interdyscyplinarności badań naukowych lub prac rozwojowych dla powstania tego wpływu (maksymalnie 1500 znaków ze spacjami dla każdej wersji językowej)',
      received_funds: 'Środki przyznane podmiotowi na realizację projektu',
      received_funds_add: 'Dodaj środki finansowe',
      received_funds_financing_institution: 'W celu wybrania instytucji finansującej należy ją dodać w sekcji instytucji finansującej projekt',
      received_funds_remove: 'Usuń środki finansowe',
      remove_bibliography: 'Usuń opis bibliograficzny',
      remove_evidence: 'Usuń charakterystykę dowodu wpływu',
      remove_file_description: 'Usuń opis pliku',
      remove_link_description: 'Usuń opis odnośnika',
      research_services: 'Usługi badawcze',
      same_discipline: 'Ta sama dyscyplina dwukrotnie',
      save_changes: 'Czy zapisać zmiany?',
      saved: 'Zapisano osiągnięcie',
      scientific_activity: 'Rodzaj działalności naukowej',
      scientific_activity_effects: 'Efekty działalności naukowej',
      social_conclusion: 'Wnioski',
      social_impact: 'Obszar wpływu społecznego',
      social_importance: 'Znaczenie wpływu społecznego',
      social_importance_tooltip: 'Przewidywane znaczenie wpływu',
      social_other_factors: 'Proszę podać inne czynniki',
      social_range_tooltip: 'Przewidywany zasięg wpływu',
      subject_role_characteristic: 'Charakterystyka roli podmiotu',
      subject_role_characteristic_tooltip: 'Charakterystyka roli podmiotu w osiągnięciu efektów działalności naukowej w języku polskim i angielskim (maksymalnie 1000 znaków ze spacjami dla każdej wersji językowej)',
      subtitle: 'Nowe osiągnięcie',
      summary: 'Podsumowanie',
      title: 'Osiągnięcie',
      total_amount: 'Środki finansowe ogółem na realizację projektu (PLN)',
      total_amount_tooltip: 'Należy wprowadzić kwotę środków finansowych ogółem, przyznanych przez instytucję na realizacje projektu w okresie ewaluacji',
      unsaved_changes: 'Masz niezapisane dane i próbujesz przejść do innej strony.',
      wrong_share: 'Dyscyplina nie może mieć udziału 0%',
      year_and_month: 'Rok i miesiąc'
    },
    show: {
      show_document: 'Typ osiągnięcia',
      subtitle: '',
      title: 'Podgląd osiągnięcia'
    }
  },
  art_achievements: {
    title: 'Rodzaj dorobku artystycznego',
    add_kind: 'Dodaj rodzaj',
    add_group: 'Dodaj grupę'
  },
  art_import: {
    title: 'Pliki XMLS importu osiągnięć artystycznych'
  },
  common: {
    back: 'Wstecz',
    change: 'Zmień',
    next: 'Dalej',
    save: 'Zapisz',
    valid: 'Waliduj'
  },
  conferences: {
    list: {
      title: 'Lista konferencji',
      subtitle: '',
      filters: {
        name: 'Nazwa',
        short: 'Skrót'
      },
      ministerial_list: 'Lista ministerialna',
      other_list: 'Inne',
      modal: {
        name: 'Nazwa',
        points2017: 'Punkty <2019',
        points2019: 'Punkty >2019',
        short: 'Skrót',
        website: 'Strona internetowa',
        title: 'Konferencja',
        cannot_delete: 'Nie można usunąć danej konferencji, ponieważ jest przypisana do zatwierdzonego osiągnięcia',
        cleaning_title: 'Usuwanie konferencji',
        cleaning_confirmation: 'Czy na pewno chcesz usunąć konferencję?',
        publications: 'Publikacje',
        duplicates: 'Duplikaty'
      }
    }
  },
  dev_sessions: {
    dev_sessions_index: {
      academy_name: 'nazwa uczelni',
      action: 'akcja',
      email: 'Adres email',
      logged_in: 'Jesteś już zalogowany',
      name: 'imię',
      password: 'Hasło',
      roles: 'role',
      surname: 'nazwisko',
      title: 'Logowanie'
    }
  },
  dictionaries: {
    dictionary_types: {
      closed: 'Zamknięty',
      open: 'Otwarty',
      global: 'Globalny'
    },
    edit: {
      dictionary_type: 'Słownik otwarty',
      subtitle: 'Edycja',
      title: 'Słowniki'
    },
    index: {
      subtitle: '',
      table_header: 'Tabela dostępnych słowników',
      title: 'Słowniki',
      art_collection: 'Kolekcja artystyczna',
      art_features: 'Cechy osiągnięć artystycznych',
      art_importance: 'Znaczenie osiągnięć artystycznych',
      art_place_of_distribution: 'Miejsce rozpowszechniania',
      art_publisher: 'Wydawnictwa artystyczne',
      author_type: 'Typ autora',
      countries: 'Państwa',
      flags: 'Flagi',
      entities: 'Podmioty',
      entity_granting_patent: 'Podmiot udzielający patent',
      entity_obtained_patent: 'Podmiot który uzyskał patent',
      impact_notification_type: 'Rodzaj zgłoszenia wpływu',
      institutions: 'Instytucje',
      keywords: 'Słowa kluczowe',
      language: 'Język publikacji',
      license: 'Licencje',
      oecd_countries: 'Kraje OECD',
      project_contests: 'Konkursy, programy, przedsięwzięcia',
      publishing_series: 'Seria wydawnicza',
      region_countries: 'Regiony patentowe',
      manager_role: 'Funkcja w projekcie',
      patent_type: 'Rodzaj patentu',
      range: 'Zasięg osiągnięcia',
      scientific_activity: 'Rodzaj działalności naukowej',
      sci_features: 'Cechy osiągnięć naukowych',
      sharing_moment: 'Czas udostępnienia',
      sharing_way: 'Sposób udostępnienia',
      social_impact: 'Obszar wpływu społecznego',
      social_importance: 'Znaczenie wpływu społecznego',
      text_version: 'Wersja tekstu'
    },
    labels: {
      actions: 'Akcje',
      description: 'Opis',
      dictionary_type: 'Typ słownika',
      name: 'Nazwa'
    }
  },
  dictionary_acceptance_words: {
    acceptance_types: {
      allow_emails: 'Wysyłanie wiadomości',
      null: 'Domyślny'
    },
    edit: {
      subtitle: 'Edycja',
      title: 'Słowa'
    },
    index: {
      subtitle: '',
      table_header: 'Tabela dostępnych warunków do zaakceptowania',
      title: 'Słowa'
    },
    labels: {
      acceptance_type: 'Typ zgody',
      actions: 'Akcje',
      name: 'Nazwa',
      status: 'Wymagane',
      switches: 'Wysyłanie wiadomości',
      type: 'Typ',
      value: 'Wartość'
    },
    new: {
      subtitle: 'Nowe',
      title: 'Słowo'
    },
    statuses: {
      null: 'Opcjonalny',
      optional: 'Opcjonaly',
      required: 'Wymagany'
    }
  },
  dictionary_acceptances: {
    index: {
      labels: {
        actions: 'Akcje',
        description: 'Opis',
        dictionaryType: 'Typ słownika',
        name: 'Nazwa'
      },
      subtitle: 'Zgód i akceptacji',
      table_header: 'Tabela dostępnych słowników',
      title: 'Słowniki'
    }
  },
  dictionary_words: {
    academy: 'Uczelnia',
    back_list: 'Wróć do listy słów',
    changed_word: 'Zastąpione słowo kluczowe zostanie usunięte',
    edit: {
      subtitle: 'Edycja',
      title: 'Słowa'
    },
    index: {
      accepted: 'Zaakceptowane',
      add: 'Dodaj słowo',
      all: 'Wszystkie',
      recent: 'Nowe',
      rejected: 'Odrzucone',
      subtitle: '',
      table_header: 'Tabela dostępnych słów',
      title: 'Słowa',
      key: 'Klucz',
      value: 'Wartość',
      value_en: 'Wartość (en)',
      status: 'Status',
      lang: 'Język',
      switch: 'Scal',
      switches: 'Zmień status',
      actions: 'Akcje'
    },
    labels: {
      accept: 'Akceptuj',
      actions: 'Akcje',
      status: 'Status',
      switches: 'Zmień status',
      value: 'Wartość',
      value_en: 'Wartość (en)',
      valueEn: 'Wartość (en)',
      key: 'Klucz',
      lang: 'Język'
    },
    new: {
      subtitle: 'Nowe',
      title: 'Słowo'
    },
    statuses: {
      recent: 'Nowy',
      accepted: 'Zaakceptowany',
      rejected: 'Odrzucony'
    }
  },
  dictionary_disciplines: {
    title: 'Dyscypliny',
    id: 'Id',
    modal_discipline: 'Dyscyplina',
    modal_field: 'Dziedzina',
    new: 'Dodaj dyscyplinę',
    field_name: 'Dziedzina',
    short: 'Kod',
    name: 'Nazwa',
    name_en: 'Nazwa (en)',
    api_id: 'PBN Id'
  },
  discipline: {
    show: {
      '3n': 'Liczba 3N',
      '3n_minuses': 'Minusy dla 3N',
      minuses_3: 'Minusy 3',
      authors: ' | 1 autor | {n} autorów | {n} autorów',
      choice: 'Wybór dyscypliny',
      limit220_num: '{num} z {out_of}',
      limit220_desc: ' slotów w ramach limitu 2,2N',
      category: 'Kategoria:',
      category_modal: 'Kryterium',
      conversion_jobs: 'Liczba etatów przeliczeniowych',
      creators: '| 1 twórca | {count} twórców | {count} twórców',
      edit: 'edytuj',
      edit_reference_values: 'Edycja wartości referencyjnych',
      elevation_treshold: 'Próg pełnego przewyższenia:',
      estimate_category: 'oszacuj kategorię',
      estimated: 'Szacunkowy wynik',
      estimated_category: 'Szacowana kategoria',
      estimated_category_none: 'brak',
      estimated_category_text: 'Informacja jak szacujemy kategorię',
      estimated_text: 'Estymacja wyniku przy zapełnieniu 100% slotów na podstawie aktualnego wyniku punktowego oraz zapełnienia slotów.',
      estimated_text_artistic: 'Estymacja wyniku przy wypełnieniu 100% limitu na podstawie aktualnego wyniku punktowego oraz wypełnienia limitu.',
      evaluation: 'Ewaluacja',
      field: 'Dziedzina nauki',
      filled: 'wypełnionych',
      filling: 'Procentowe wypełnienie dostępnych slotów',
      first_criterium: 'Pierwsze kryterium',
      first_criterium_points_per_n: 'wynik punktowy w pierwszym kryterium',
      ignore_minuses: 'Uwzględniaj minusy',
      last_launch: 'Data ostatniego uruchomienia ewaluacji',
      limit: 'Wypełnienie limitu',
      limits_filling: 'limitu',
      manager: 'Kierownik dyscypliny | Kierownik dyscypliny | Kierownicy dyscypliny',
      minuses: 'Minus dla dyscypliny',
      missing_jobs: ' | Brakuje 1 | Brakuje {n} | Brakuje {n} | Brakuje {n}',
      no_records: 'Niestety nie odnaleziono pracowników spełniających warunki wyszukiwania',
      no_publications: 'Nie można uruchomić ewaluacji bez zgłoszonych osiągnięć',
      no_required_working_time: 'Brak wymaganych etatów',
      number_n: 'Liczba N',
      number_n_average: 'Liczba N (średnia z lat {from}-{to})',
      number_n_last_year: 'Liczba N (rok {year})',
      number_n_persons: 'pracownicy zgłoszeni do liczby N',
      patents: 'Patenty',
      per_n: 'na N',
      persons: 'pracownicy z wybraną dyscypliną',
      persons_one: 'pracownicy z wybraną tylko tą dyscypliną',
      points: 'punktów | punkt | punkty | punktów | punktu',
      publications_accepted: 'Osiągnięcia ze zgodą na ewaluację',
      publications_accepted_to_eval: 'zgoda na ewaluacje osiągnięcia w dyscyplinie',
      publications_statement: 'Osiągnięcia z przypisaną dyscypliną',
      publications_assigned_statement: 'deklaracja dyscypliny dla osiągnięcia',
      reference_values: 'Wartości referencyjne',
      reported_achievements: 'Zgłoszone osiągnięcia',
      result: 'Aktualny wynik',
      run: 'Uruchom',
      scientists_list: 'Lista pracowników',
      second_criterium: 'Drugie kryterium',
      second_criterium_count: 'liczba osiągnięć',
      second_criterium_list: 'Efekty finansowe badań naukowych i prac rozwojowych',
      second_criterium_points: 'punkty',
      second_criterium_points_per_n: 'wynik punktowy w drugim kryterium',
      simulate_employment: 'Symulacja etatów',
      simulate_employment_success: 'Pomyślnie zaktualizowano symulację etatów',
      slots: 'slotów | slot | sloty | slotów | slota',
      slots_filling: 'slotów',
      slots_left: ' | Pozostał 1 slot do wypełnienia | Pozostały {n} sloty do wypełnienia | Pozostało {n} slotów do wypełnienia',
      sort: 'Sortuj według',
      sort_by: {
        fillings: 'wypełnienie slotów',
        limits: 'wypełnienie limitu',
        names: 'nazwisko',
        points: 'liczba punktów',
        titles: 'tytuł'
      },
      statements: 'Parametry szczegółowe',
      third_criterium: 'Trzecie kryterium',
      third_criterium_count: 'liczba osiągnięć',
      third_criterium_points: 'punkty',
      third_criterium_points_per_n: 'wynik punktowy w trzecim kryterium',
      title: 'Dyscyplina',
      two_disciplines: '2 dyscypliny',
      working_time: 'Wymiar pracy'
    }
  },
  discipline_statements: {
    add_another: 'Dodaj kolejną dyscyplinę',
    add_statement: 'Dodaj kolejne oświadczenie',
    dissertation: 'Rozprawa doktorska',
    doctoral_school: 'Szkoła doktorska',
    correct_discipline: 'Dyscyplina została zadeklarowana pomyślnie',
    correct_doctoral_school: 'Wybrano szczegóły udziału w szkole doktorskiej',
    correct_n_statement: 'Pomyślnie zapisano informacje o zgłoszeniu do N',
    remove_discipline: 'Usuń dyscyplinę',
    remove_statement: 'Usuń oświadczenie',
    statement: 'Oświadczenie',
    title: 'Nowe oświadczenie o dyscyplinach',
    wrong_discipline: 'Dyscyplina nie została zadeklarowana',
    wrong_number_n: 'Oświadczenie do liczby N nie zostało zadeklarowane.',
    wrong_year: 'Został podany rok niezgodny z obowiązującą ewidencją'
  },
  disciplines: {
    chosen_evaluation_error: 'Musi zostać wybrany okres ewaluacji',
    evaluations: 'Okresy ewaluacji',
    multiple_evaluation: 'Masowa ewaluacja',
    show_all: 'Wszystkie dyscypliny',
    show_evaluated: 'Dyscypliny ewaluowane',
    show_with_achievements: 'Dyscypliny z osiągnięciami',
    title: 'Dyscypliny'
  },
  employee: {
    academic_title: 'Tytuł naukowy',
    active: 'Aktywna',
    add_faculty: 'Dodaj jednostkę',
    add_id: 'Dodaj identyfikator',
    add_name: 'Dodaj imię i nazwisko',
    additional_identifiers: 'Dodatkowe identyfikatory',
    archived: 'Zarchiwizowane',
    doctorant: 'Doktorant',
    edit_additional_identifiers: 'Edytuj dodatkowe identyfikatory',
    edit_employee_api_id: 'Edytuj ID',
    editing_employee_api_id: 'Edycja zewnętrznego identyfikatora',
    edit_employee_flags: 'Edytuj flagi',
    edit_identities: 'Edycja imion i nazwisk',
    employee: 'Pracownik',
    employees_count: 'Powiązani współautorzy',
    external_id: 'Zewnętrzny identyfikator:',
    faculties: 'Edytuj jednostki',
    faculties_count: 'Jednostki powiązanych współautorów',
    faculties_list: 'Jednostka:',
    faculty_error: 'Pracownik jest już przypisany do danej jednostki',
    identities: 'Edytuj tożsamości',
    key: 'Klucz',
    keywords_count: 'Słowa kluczowe z publikacji',
    name: 'Imię',
    no_employee: 'Nie ma pracownika z takim ID',
    no_rights: 'Nie masz dostępu do tej części aplikacji',
    preferred: 'Preferowana',
    remove_faculty: 'Usuń jednostkę',
    remove_id: 'Usuń identyfikator',
    remove_identity: 'Usuń tożsamość',
    save_and_close: 'Zapisz i zamknij',
    set_employee_confirm: 'Zmiana w pracownika',
    set_employee_confirm_text: 'Czy na pewno chcesz zamienić doktoranta w pracownika?',
    show_less: 'Pokaż mniej',
    show_more: 'Pokaż więcej',
    skip_restrictions: "Pomijaj aktualizację 'Wyłączenie z restrykcji'",
    surname: 'Nazwisko',
    title: 'Pracownik',
    value: 'Wartość'
  },
  employees: {
    title: 'Pracownicy'
  },
  entity_project: {
    address: 'Adres',
    evaluation: 'Podmiot ewaluowany',
    in_territory: 'Siedziba na terytorium RP',
    modal: {
      title: 'Podmiot realizujący projekt'
    },
    name: 'Nazwa podmiotu',
    university: 'Należy do systemu szkolnictwa wyższego i nauki',
    subtitle: '',
    title: 'Podmioty realizujące projekty'
  },
  evaluations: {
    list: {
      add_evaluation: 'Dodaj okres ewaluacji',
      evaluation_period: 'Okres ewaluacji',
      evaluation_years: 'Lata ewaluacji',
      name: 'Nazwa',
      subtitle: '',
      title: 'Okresy ewaluacji'
    }
  },
  faculties: {
    created: 'Jednostka utworzona',
    droped: 'Jednostka usunięta',
    list: {
      api_id: 'Identyfikator zewnętrzny',
      base_deleting: 'Usuwanie jednostki',
      base_deleting_confirmation: 'Czy na pewno chcesz usunąć jednostkę i jej podjednostki?',
      edit_faculty: 'Edytuj jednostkę',
      name: 'Nazwa jednostki',
      new: 'Dodaj jednostkę',
      parent: 'Nadrzędny',
      remove_faculty: 'Usuń jednostkę',
      short: 'Skrót',
      subtitle: '',
      title: 'Lista jednostek'
    }
  },
  f_institution: {
    address: 'Adres',
    modal: {
      title: 'Instytucja finansująca'
    },
    name: 'Nazwa instytucji',
    on_list: 'Ministerialne',
    short: 'Skrót',
    subtitle: '',
    title: 'Instytucje finansujące'
  },
  f_source: {
    evaluation: 'Ewaluacja',
    modal: {
      title: 'Źródło finansowania'
    },
    modifier: 'Modyfikator',
    name: 'Nazwa',
    subtitle: '',
    title: 'Źródła finansowania'
  },
  histories: {
    changed_publications: 'Edytowane osiągnięcia',
    changes: 'Zmiany',
    created_publications: 'Stworzone osiągnięcia',
    dates: 'Daty',
    no_records: 'Nie znaleziono zmian w podanym okresie',
    perform: 'Wykonaj',
    report_error: 'Błąd generowania raportu',
    subtitle: '',
    title: 'Historia',
    user: 'Użytkownik'
  },
  import_polon: {
    title: 'Import osiągnięć POLon',
    import_publications_from_polon: 'Import osiągnięć z POLon',
    import_mode: 'Tryb importu',
    import_type: 'Typ importu',
    username: 'Nazwa użytkownika',
    password: 'Hasło',
    institution_id: 'ID instytucji',
    import: 'Importuj',
    add_employees: 'Dodawanie pracowników'
  },
  import_polon_employees: {
    title: 'Import danych pracowników i doktorantów POLon',
    import_employees_from_polon: 'Import danych pracowników z POLon',
    import_mode: 'Tryb importu',
    name: 'Imię pracownika',
    surname: 'Nazwisko pracownika',
    username: 'Nazwa użytkownika',
    password: 'Hasło',
    institution_id: 'ID instytucji',
    import_employees: 'Importuj pracowników',
    import_phd_students: 'Importuj doktorantów',
    import_phd_students_from_polon: 'Import danych doktorantów z POLon',
    data_type: 'Typ pobieranych danych',
    student_name: 'Imię doktoranta',
    student_surname: 'Nazwisko doktoranta'
  },
  import_publication: {
    title: 'Import publikacji i pracowników',
    import_publications_from_api: 'Import publikacji z api',
    import_employees_from_api: 'Import pracowników z api',
    import: 'Importuj'
  },
  import_pbn_api: {
    title: 'Import publikacji z API PBN',
    with_discipline: 'Import z przypisaniem dyscyplin',
    year_from: 'Rok od',
    year_to: 'Rok do',
    import: 'Importuj',
    import_fees: 'Import opłat',
    import_publications: 'Import publikacji',
    pbn_id: 'PBN ID publikacji'
  },
  import_pbn_dictionaries: {
    app_id: 'App-Id',
    app_token: 'App-Token',
    import: 'Importuj',
    import_dictionaries_from_pbn: 'Importuj słowniki z PBN',
    import_type: 'Typ importu',
    title: 'Import słowników z API PBN'
  },
  import_polon_dictionaries: {
    import_dictionaries_from_polon: 'Importuj słowniki z POL-on',
    title: 'Import słowników z API POL-on'
  },
  instances: {
    created: 'Instancja utworzona',
    droped: 'Instancja usunięta',
    list: {
      api_set_discipline: 'Ustawienie domyślnej dyscypliny w API',
      base_cleaning: 'Czyszczenie bazy',
      base_cleaning_confirmation: 'Czy na pewno chcesz wyczyścić bazę danej instancji?',
      base_deleting: 'Usuwanie bazy',
      base_deleting_confirmation: 'Czy na pewno chcesz usunąć bazę danej instancji?',
      base_updating: 'Aktualizacja słowników',
      base_updating_confirmation: 'Czy na pewno chcesz zaktualizować słowniki danej instancji?',
      block_set_discipline: 'Zablokuj automatyczne ustawianie trzeciego oświadczenia podczas aktualizacji osiągnięcia przez API oraz aktualizacji pracownika z POL-on',
      cyclical_evaluation: 'Cykliczna ewaluacja',
      change_language: 'Zmiana języka',
      copy_conference: 'Kopiowanie konferencji dla tomów pokonferencyjnych',
      default_evaluation: 'Domyślna ewaluacja',
      default_year_filter: 'Domyślny filtr lat na liście osiągnięć',
      export_fees: 'Eksportuj opłaty',
      export_only_new: 'Eksportuj tylko nowe osiągnięcia do PBN',
      locked: 'Blokada',
      name: 'Nazwa instancji',
      new: 'Dodaj instancję',
      pbn_cyclical_update: 'Cykliczna aktualizacja PBN',
      pbn_update_mode: 'Tryb aktualizacji PBN',
      polon_art: 'Osiągnięcia artystyczne',
      polon_commercialization: 'Komercjalizacje',
      polon_cyclical_update: 'Cykliczna aktualizacja osiagnięć z POL-on',
      polon_employee_cyclical_update: 'Cykliczna aktualizacja pracowników z POL-on',
      polon_institution: 'POL-on ID instytucji',
      polon_patent: 'Patenty',
      polon_phd_student_cyclical_update: 'Cykliczna aktualizacja doktorantów z POL-on',
      polon_project: 'Projekty',
      polon_service: 'Serwisy',
      polon_update_mode: 'Tryb aktualizacji POL-on',
      recalculated_slots: 'Sloty przeliczone poprawnie',
      search_by_name: 'Szukaj pracowników po imieniu i nazwisku',
      simulate_employment: 'Symuluj przyszłe etaty w wyliczeniach',
      subtitle: '',
      title: 'Lista instancji',
      update_frequency: 'Częstotliwość aktualizacji',
      update_redaction: 'Automatyczna aktualizacja wydmuszek'
    },
    rights: {
      subtitle: '',
      title: 'Uprawnienia'
    },
    updated: 'Słowniki zostały zaktualizowane'
  },
  login: {
    algorithm: 'Algorytm punktowy i kwalifikujący dorobek naukowy',
    header: 'Analityka i ewidencja naukowa',
    login_btn: 'Zaloguj się',
    main_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor scelerisque lectus, eget maximus ' +
      'dolor.',
    monitoring: 'Monitorowanie i symulacja udziałów jednostkowych w slotach publikacyjnych',
    tool: 'Narzędzie do analityki i ewidencji osiągnięć naukowych i artystycznych'
  },
  messages: {
    index_tabs: {
      draft_messages_tab: {
        title: 'Wersje robocze'
      },
      group_messages_tab: {
        group: 'Grupowe',
        title: 'Wiadomości'
      }
    },
    messages_new: {
      subtitle: 'NOWA WIADOMOŚĆ',
      title: 'WIADOMOŚCI'
    },
    messages_index: {
      draft: 'Robocze',
      group: 'Grupowe'
    }
  },
  messages_list: {
    title: 'SKRZYNKA ODBIORCZA'
  },
  number_n_statement: {
    title: 'Edycja zgłoszenia do N dla dyscypliny'
  },
  orcid_integration: {
    import_publications: 'Importuj osiągnięcia',
    import_successful: 'Osiągnięcia zostały zaimportowane',
    same_id: 'Podałeś dotychczasowe ORCID ID',
    title: 'Integracja z ORCID'
  },
  orcid_publications: {
    all_disciplines: 'Pokaż wszystkie dyscypliny',
    artistic_wrong_discipline: 'Dyscyplina twórcy nie jest zgodna z dyscypliną osiągnięcia artystycznego',
    authors: 'Autorzy/Współautorzy',
    authors_translators: 'Autorzy/Współautorzy przekładu',
    breeders: 'Hodowcy/Współhodowcy',
    change_orcid: 'Zmień ORCID',
    change_submitted_date: 'Data wyrażenia zgody',
    co_author: 'Współautor',
    co_contractor: 'Współwykonawca',
    co_creator: 'Współtwórca',
    co_redactor: 'Współredaktor',
    conference: 'Konferencja',
    conference_series: 'Seria konferencji',
    contractors: 'Wykonawcy',
    contributor: 'Kontrybutor',
    contributors: 'Kontrybutorzy',
    creators: 'Twórcy/Współtwórcy',
    discipline: 'Dyscyplina',
    doctoral_student: 'Doktorant',
    duplicate_author: 'Zduplikowany pracownik',
    edit_authors: 'Edytuj współautorów',
    edit_authors_translators: 'Edytuj współautorów przekładu',
    edit_breeders: 'Edytuj hodowców',
    edit_conference: 'Edytuj konferencję',
    edit_contractors: 'Edytuj współwykonawców',
    edit_contributors: 'Edytuj kontrybutorów',
    edit_creators: 'Edytuj współtwórców',
    edit_discipline: 'Edytuj dyscyplinę',
    edit_faculty: 'Edytuj jednostki',
    edit_original_authors: 'Edytuj autorów oryginału',
    edit_periodical: 'Edytuj czasopismo',
    edit_publisher: 'Edytuj wydawnictwo',
    edit_redactors: 'Edytuj współredaktorów',
    edit_redactors_translators: 'Edytuj współredaktorów przekładu',
    edit_submitted_date: 'Edytuj datę wyrażenia zgody',
    empty_author: 'Nie wybrano pracownika',
    evaluation_agreement: 'Zgoda na ewaluację',
    faculty: 'Jednostka',
    from_university: 'Afiliacja',
    import_successful: 'Import zakończony sukcesem',
    new_publications: 'Nowe osiągnięcie',
    new_publications_count: ' | pobrano 1 nowe osiągnięcie z bazy ORCID | pobrano {count} nowe osiągnięcia z bazy ORCID | pobrano {count} nowych osiągnięć z bazy ORCID',
    no_affiliation: 'Brak afiliacji',
    no_discipline: 'Brak oświadczenia o dyscyplinach w okresie ewaluacji',
    no_ndiscipline: 'Brak oświadczenia o zaliczeniu do liczby N w roku publikacji',
    no_new_publications: 'Brak nowych osiągnięć w bazie ORCID od czasu ostatniej synchronizacji.',
    old_publications: 'Poprzednie osiągnięcia',
    on_day: 'W dniu',
    original_authors: 'Autorzy oryginału',
    original_co_author: 'Współautor oryginału',
    other_contributors: 'Pozostali autorzy',
    percentage: 'Udział procentowy',
    periodical: 'Czasopismo',
    periodical_publisher: 'Wydawnictwo czasopisma',
    previous_synchronizations: 'Poprzednie synchronizacje',
    publisher: 'Wydawnictwo',
    redactors: 'Redaktorzy/Współredaktorzy',
    redactors_translators: 'Redaktorzy/Współredaktorzy przekładu',
    related_publications_count: ' | zostało powiązane 1 osiągnięcie | zostały powiązane {count} osiągnięcia | zostało powiązanych {count} osiągnięć',
    submitted_date: 'Data wyrażenia zgody',
    too_big_percentage: 'Suma wszystkich udziałów procentowych wynosi więcej niż 100%',
    wrong_discipline: 'Wybrana dyscyplina nie jest zgodna z oświadczeniem'
  },
  pages_list: {
    new_page: 'Nowa strona',
    subtitle: 'Lista stron',
    title: 'CMS'
  },
  periodicals: {
    list: {
      importance2018: 'Do 2018',
      importance2019: 'Od 2019',
      title: 'Lista czasopism',
      disciplines: 'Dyscypliny',
      subtitle: '',
      filters: {
        name: 'Nazwa',
        issn: 'ISSN'
      },
      ministerial_list: 'Lista ministerialna',
      other_list: 'Inne',
      modal: {
        title: 'Czasopismo',
        name: 'Nazwa',
        name2: 'Nazwa 2',
        issn: 'ISSN',
        issn2: 'ISSN 2',
        eissn: 'eISSN',
        eissn2: 'eISSN 2',
        points2017: 'Punkty do 2018',
        points2019: 'Punkty od 2019',
        cannot_delete: 'Nie można usunąć danego czasopisma, ponieważ jest przypisane do zatwierdzonego osiągnięcia',
        cleaning_title: 'Usuwanie czasopisma',
        cleaning_confirmation: 'Czy na pewno chcesz usunąć czasopismo?',
        publications: 'Publikacje',
        duplicates: 'Duplikaty',
        periodical_website: 'Strona internetowa czasopisma',
        periodical_publisher: 'Wydawnictwo czasopisma'
      }
    },
    wrong_discipline: 'Wybrana dyscyplina nie jest zgodna z czasopismem'
  },
  pbn_token: {
    title: 'Integracja PBN',
    subtitle: '',
    generate: 'Generuj token',
    save: 'Zapisz'
  },
  publications: {
    history: {
      actual_version: 'Aktualna wersja',
      add_author: 'Dodał/a autora',
      add_dorobek: 'Dodał/a dorobek',
      add_related_project: 'Dodał/a projekt współfinansowany',
      add_source: 'Dodał/a źródło',
      calc_points: 'Przeliczył/a punkty',
      duplicate: 'Połączył/a duplikaty',
      changed_by: 'zmienił/a dane osiągnięcia',
      discipline: 'Dyscyplina',
      edit_author: 'Zmodyfikował/a autora',
      edit_dorobek: 'Zmodyfikował/a dorobek',
      edit_source: 'Zmodyfikował/a źródło',
      evaluation: 'Wykonał/a ewaluację',
      fields: {
        abstract: 'Abstrakt',
        accepted: 'Akceptacja',
        alternative_points: 'Alternatywne punkty',
        amount: 'Wysokość przychodu',
        anotherContestName: 'Inna nazwa konkursu, programu lub przedsięwzięcia',
        apiId: 'Identyfikator wewnętrzny',
        artCharacteristic: 'Charakterystyka osiągnięcia artystycznego',
        artDisciplineId: 'Dyscyplina artystyczna',
        artImportance: 'Kategoria osiągnięcia',
        artKindId: 'Rodzaj osiągnięcia artystycznego',
        artPlaceOfDistribution: 'Miejsce rozpowszechnienia',
        artPublisherId: 'Wydawnictwo artystyczne',
        artTypeId: 'Typ osiągnięcia artystycznego',
        authorType: 'Typ autora',
        bookProjectFnp: 'Monografia powstała w wyniku realizacji projektu finansowanego przez FNP',
        bookProjectNprh: 'Monografia powstała w ramach programu NPRH',
        bookProjectUe: 'Monografia powstała w ramach programu ramowego Unii Europejskie',
        bookProjectNcn: 'Monografia powstała w wyniku realizacji projektu finansowanego przez NCN',
        bookWithChapters: 'Monografia z oznaczonym autorstwem rozdziałów',
        botanicName: 'Nazwa botaniczna',
        chapterTitle: 'Tytuł rozdziału',
        collectionCountryId: 'Kraj, w którym znajduje się kolekcja lub miejsce o szczególnym znaczeniu dla kultury',
        conferenceCountry: 'Kraj konferencji',
        conferenceFrom: 'Data rozpoczęcia konferencji',
        conferenceId: 'Nazwa serii konferencji',
        conferenceMaterial: 'Materiał konferencyjny',
        conferenceName: 'Nazwa edycji konferencji',
        conferenceShort: 'Nazwa skrócona edycji konferencji',
        conferenceTo: 'Data zakończenia konferencji',
        conferenceTown: 'Miejscowość konferencji',
        conferenceWebsite: 'Strona internetowa edycji konferencji',
        contestName: 'Nazwa konkursu, programu lub przedsięwzięcia',
        createdAsGrant: 'Powstała w ramach grantu',
        countryFirstPublicId: 'Kraj pierwszego upublicznienia',
        createdEntityName: 'Nazwa utworzonego podmiotu',
        datePatentTranslation: 'Data złożenia tłumaczenia na język polski',
        decisionDate: 'Data zgłoszenia w Urzędzie Patentowym RP lub we właściwym urzędzie za granicą',
        delivered: 'Pozytywna ocena ekspercka',
        disciplineId: 'Dyscyplina',
        disseminatingScience: 'Upowszechnianie nauki',
        editionInProject: 'Powstała w wyniku realizacji projektu',
        employeeId: 'ID pracownika',
        entityGrantingPatent: 'Podmiot udzielający patent/prawo ochronne/przyznający wyłączne prawo',
        entityObtainedPatentId: 'Podmiot uprawniony',
        evaluationOrder: 'Kolejność uwzględnienia w ewaluacji',
        evaluationYear: 'Rok ewaluacji',
        exhibitionDesignation: 'Oznaczenie wystawy',
        expertPoints: 'Punkty w ocenie eksperckiej',
        externalLink: 'Zewnętrzny URL',
        exportResult: 'Wynik eksportu',
        facultyIds: 'Jednostki autora',
        features: 'Cechy osiągnięcia',
        feeAct: 'Środki finansowe, o których mowa w art. 365 pkt. 2 ustawy',
        feeAmount: 'Kwota',
        feeFree: 'Publikacja bezkosztowa',
        feeOther: 'Inne środki',
        feeProject: 'Środki finansowe przyznane na realizację projektu w zakresie badań naukowych lub prac rozwojowych',
        financingSourceId: 'Źródło finansowania',
        firstApplication: 'Czy patent obejmuje uprzednie pierwszeństwo',
        flags: 'Flagi',
        fundedCompetitionMode: 'Finansowany lub współfinansowany w trybie konkursowym',
        impactDescriptionJustification: 'Uzasadnienie oceny opisu wpływu',
        impactNotificationTypeId: 'Rodzaj zgłoszenia wpływu',
        implementationCountryId: 'Kraj realizacji',
        implementationYear: 'Rok realizacji',
        incomeByEntity: 'Czy przychód został uzyskany przez podmiot utworzony przez ten podmiot w celu komercjalizacji?',
        interdisciplinarity: 'Interdyscyplinarność',
        inMenList: 'Z listy ministerialnej',
        isCommercialization: 'Rodzaj danych finansowych',
        issue: 'Numer zeszytu',
        issueNumber: 'Numer wydania',
        keywords: 'Słowa kluczowe',
        language: 'Język',
        lastModifiedAt: 'Data ostatniej modyfikacji',
        leadership: 'Edycja naukowa tekstu źródłowego',
        licence: 'Otwarta licencja',
        mainSource: 'Źródło',
        monographId: 'ID monografii',
        name: 'Imię i nazwisko',
        numberOfMonths: 'Open Access - liczba miesięcy',
        openAccess: 'Open Access',
        originalAuthors: 'Autorzy oryginału',
        originalId: 'ID oryginału',
        originalLanguage: 'Język oryginału',
        otherContributors: 'Pozostali autorzy',
        otherEntity: 'Przyznany innemu podmiotowi niż ewaluowany',
        ownAmount: 'Wysokość wkładu własnego',
        pages: 'Strony',
        part: 'Udział procentowy',
        patentApplicationNumber: 'Numer zgłoszenia',
        patentApplicationNumberInUprp: 'Numer zgłoszenia w UPRP',
        patentCountries: 'Państwa, na terytorium których uzyskano ochronę',
        patentNumber: 'Numer patentu/prawa ochronnego w księdze ochrony',
        patentOecdCountryId: 'Kraj OECD',
        patentOffice: 'Zgłoszony w Urzędzie Patentowym RP',
        patentType: 'Typ patentu',
        periodicalId: 'Czasopismo',
        plantVariety: 'Nazwa odmiany',
        points: 'Punkty',
        postConferenceVolume: 'Tom pokonferencyjny',
        principalName: 'Podmiot zlecający',
        priorPriorityCountry: 'Kraj uprzedniego pierwszeństwa',
        priorPriorityDate: 'Data uprzedniego pierwszeństwa',
        priorityNumber: 'Numer uprzedniego zgłoszenia',
        projectAbstractEn: 'Streszczenie projektu w języku angielskim',
        projectAcronym: 'Akronim projektu',
        projectContestEdition: 'Edycja konkursu, programu lub przedsięwzięcia',
        projectEnd: 'Data zakończenia realizacji projektu',
        projectIndividual: 'Projekt prowadzony samodzielnie',
        projectNumber: 'Numer projektu',
        projectPriorityName: 'Nazwa działania lub priorytetu',
        projectStart: 'Data rozpoczęcia realizacji projektu',
        projectTitleEn: 'Tytuł projektu w języku angielskim',
        projectTitlePl: 'Tytuł projektu w języku polskim',
        projectTypes: 'Typy projektu',
        publicationDate: 'Data publikacji',
        publicationPlace: 'Miejsce wydania',
        publisherId: 'Wydawnictwo',
        publisherTitle: 'Nazwa wydawnictwa',
        publishingSeriesId: 'Seria wydawnicza',
        publishingSeriesNumber: 'Numer w serii wydawniczej',
        qualityScientificActivity: 'Uwzględnione w ewaluacji jakości działalności naukowej',
        rangeId: 'Zasięg',
        rejectionReason: 'Powód odrzucenia',
        reviewArticle: 'Artykuł recenzyjny',
        scientificActivityId: 'Rodzaj działalności naukowej',
        serviceEntity: 'Nazwa podmiotu utworzonego w celu komercjalizacji',
        setDiscipline: 'Dyscyplina ustawiona',
        settlementDate: 'Data rozliczenia środków',
        sharingDate: 'Data udostępnienia publikacji',
        sharingDateMonth: 'Data udostępnienia publikacji - miesiąc',
        sharingDateYear: 'Data udostępnienia publikacji - rok',
        sharingMoment: 'Czas udostępnienia',
        sharingWay: 'Sposób udostępniania',
        size: 'Strony',
        slots: 'Sloty',
        socialImpactId: 'Obszar wpływu społecznego',
        socialImportanceId: 'Znaczenie wpływu społecznego',
        socialOtherFactors: 'Obszar wpływu społecznego - inne czynniki',
        sourceId: 'ID pracownika',
        submitDate: 'Data zgody',
        submitted: 'Zgoda na ewaluację',
        textVersion: 'Wersja tekstu',
        title: 'Tytuł',
        totalAmount: 'Środki finansowe przyznane ogółem na realizację projektu',
        translationOther: 'Przekład na inny język nowożytny',
        translationPl: 'Przekład na język polski',
        volume: 'Numer tomu',
        webOfScience: 'Web of Science',
        wosScopus: 'WoS/Scopus',
        year: 'Rok',
        yearFirstPublic: 'Rok pierwszego upublicznienia'
      },
      import_orcid: 'Zaimportował/a z ORCID',
      import_pcg: 'Zaimportował/a z PCG',
      import_pbn: 'Zaimportował/a z PBN',
      permit_to_eval: 'Zmienił/a zgodę na ewaluację',
      previous_version: 'Poprzednia wersja',
      publication_type: 'Typ publikacji',
      remove_author: 'Skasował/a autora',
      remove_dorobek: 'Skasował/a dorobek',
      remove_related_project: 'Skasował/a współfinansowany projekt',
      remove_source: 'Skasował/a źródło',
      set_discipline: 'Ustawił/a dyscyplinę',
      status: 'Status',
      status_pbn: 'Status PBN',
      title: 'Historia osiągnięcia'
    },
    types: {
      article: 'Artykuł',
      artistic_activity: 'Działalność artystyczna',
      chapter: 'Rozdział monografii',
      commercialization: 'Komercjalizacja',
      conference: 'Konferencja',
      environmental_impact: 'Wpływ na środowisko',
      monograph: 'Monografia',
      monograph_and_redaction: 'Monografia i redakcja',
      monograph_redaction: 'Redakcja monografii',
      other: 'Inna',
      patent: 'Patent',
      project: 'Projekt',
      service: 'Usługi badawcze'
    },
    comments: {
      edit_hover: 'Edytuj komentarz',
      modal_title: 'Komentarze',
      add_comment: 'Dodaj komentarz',
      edit_comment_btn: 'Edytuj komentarz',
      textarea_label: 'Komentarz'
    }
  },
  publishers: {
    list: {
      title: 'Lista wydawnictw',
      subtitle: '',
      filters: {
        name: 'Nazwa'
      },
      ministerial_list: 'Lista ministerialna',
      other_list: 'Inne',
      modal: {
        title: 'Wydawnictwo',
        name: 'Nazwa',
        points: 'Punkty',
        points2017: 'Punkty',
        points2019: 'Punkty >2019',
        cannot_delete: 'Nie można usunąć danego wydawnictwa, ponieważ jest przypisane do zatwierdzonego osiągnięcia',
        cleaning_title: 'Usuwanie wydawnictwa',
        cleaning_confirmation: 'Czy na pewno chcesz usunąć wydawnictwo?',
        publications: 'Publikacje',
        duplicates: 'Duplikaty'
      }
    }
  },
  report_files: {
    academy: 'Uczelnia',
    file_date: 'Data utworzenia',
    file_name: 'Nazwa pliku',
    user: 'Użytkownik',
    list: {
      subtitle: '',
      title: 'Pliki raportów'
    }
  },
  role_choose: {
  },
  scientist_dashboard: {
    title: 'Pulpit'
  },
  scientists: {
    list: {
      add_employee: 'Dodaj pracownika',
      adding_employee: 'Dodawanie pracownika',
      branches_statement: 'Dyscyplina',
      by_branches: 'Dyscyplina',
      by_names: 'Imię i nazwisko',
      by_unit: 'Jednostka',
      calculate_slots: 'Przelicz sloty',
      calculate_slots_confirmation: ' | Czy na pewno chcesz przeliczyć sloty 1 pracownika? | Czy na pewno chcesz przeliczyć sloty {count} pracowników?\',',
      delete_employee: 'Usuń pracownika',
      delete_employee_confirm: 'Usuwanie pracownika',
      delete_employee_confirm_text: 'Czy na pewno chcesz usunąć pracownika?',
      delete_employee_failure: 'Pracownik nie został usunięty ze względu na to że',
      delete_employee_success: 'Pomyślnie usunięto pracownika',
      doctoral_student: 'Doktorant',
      doctoral_students: 'Doktoranci',
      employee_export: 'Eksport XLS',
      employee_export_docx: 'Eksport DOC',
      employee_export_json: 'Eksport JSON',
      employee_export_pdf: 'Eksport PDF',
      employee_export_confirmation: ' | Czy na pewno chcesz wykesportować 1 pracownika? | Czy na pewno chcesz wyeksportować {count} pracowników?',
      employee_export_failure: 'Eksport pracowników zakończony niepowodzeniem',
      employee_export_success: 'Eksport pracowników zakończony pomyślnie',
      end_of_year: 'Dyscyplina na koniec roku',
      fields: {
        actions: 'Akcje',
        author_unit: 'Jednostka autorów',
        branches: 'Dyscypliny',
        full_name: 'Imię i nazwisko',
        number_n: 'Zgłoszenie do N',
        points: 'Punkty',
        publications: 'Liczba osiągnięć w aktualnym okresie ewaluacji',
        sec_branches: 'Dyscyplina 2',
        slots: 'Wypełnienie limitu slotów',
        submited: 'Liczba osiągnięć zgłoszonych',
        submited_mnisw: 'Liczba wykazanych osiągnięć (ministerialne)',
        surname: 'Nazwisko',
        unit: 'Jednostka',
        modified_at: 'Data ostatniej modyfikacji'
      },
      filter_scientists: 'Filtruj pracowników',
      name: 'Imię',
      surname: 'Nazwisko',
      academic_title: 'Tytuł naukowy',
      academic_title_en: 'Angielski tytuł naukowy',
      conducts_scientific_activity: 'Prowadzi działaność naukową',
      conducts_scientific_activity_tooltip: 'Wskazuje czy pracownik jest zatrudniony na stanowisku badawczym lub badawczo-dydaktycznym i może być na tej podstawie zaliczony do ewaluacji',
      exemption_from_restrictions: 'Wyłączenie z restrykcji',
      exemption_from_restrictions_tooltip: 'Wskazuje czy pracownik spełnia przesłankę, która pozwala na brak dostarczania sankcji dla dyscypliny w wybranym okresie ewaluacyjnym',
      wrong_employee: 'Nie udało się dodać pracownika',
      required_fields: 'pola wymagane',
      no_branch: 'Nie wybrano dyscypliny',
      no_ministerial: 'Brak zgłoszonych ministerialnych lub z pozytywną oceną ekspercką',
      no_number_n: 'Brak zgłoszenia do N',
      no_points: 'Brak punktowanych osiągnięć',
      not_enough_slots: 'Liczba osiągnięć niewystarczająca do maksymalnego wypełnienia dostępnego limitu udziałów jednostkowych',
      has_jobs: 'Podano zatrudnienie',
      has_jobs_tooltip: 'Wskazuje czy pracownik ma podany etat lub część etatu w wybranym okresie',
      has_publications: 'Osiągnięcia',
      has_publications_tooltip: 'Wskazuje czy pracownik ma przypisane jakiekolwiek osiągnięcie w wybranym okresie ewaluacyjnym',
      has_special_publications: 'Osiągnięcia ministerialne',
      has_special_publications_tooltip: 'Wskazuje czy pracownik ma przypisane osiągnięcie z listy ministerialnej w wybranym okresie ewaluacyjnym',
      not_applicable: 'Nie dotyczy',
      number_n_statement: 'Liczba N',
      publications_statement: 'Ewaluacja osiągnięcia',
      publications_statement_tooltip: 'Wskazuje czy pracownik ma przypisane co najmniej jedno tzw. III oświadczenie (zgłaszające osiągnięcie do ewaluacji) w wybranym okresie ewaluacyjnym',
      scientists_list: 'Lista pracowników',
      see_achievements: 'Zobacz osiągnięcia',
      select: 'Wybierz',
      select_employee: 'Zaznacz pracowników',
      sort_by: 'Sortuj według',
      sort_by_asc: 'Sortowanie rosnące',
      sort_by_desc: 'Sortowanie malejące',
      statement_made: 'Złożono oświadczenie',
      subtitle: '',
      title: 'Pracownicy',
      two_branches: 'Wybrano dwie dyscypliny'
    }
  },
  simple_messages: {
    simple_messages_index: {
      filter_messages: 'Filtruj wiadomości',
      no_records: 'Brak wiadomości',
      title: 'Wiadomości'
    },
    simple_messages_new: {
      subtitle: 'Nowa wiadomość',
      title: 'Wiadomości'
    },
    simple_messages_show: {
      title: 'Wiadomość'
    },
    statuses: {
      all: 'Wszystkie',
      read: 'Przeczytane',
      recent: 'Nieprzeczytane'
    }
  },
  statements: {
    list: {
      discipline: 'Wybór dyscyplin',
      doctoral_school: 'Szkoła doktorska',
      fields: {
        dates: 'Data złożenia',
        disciplines: 'Dyscypliny',
        full_name: 'Imię i nazwisko'
      },
      no_actual_discipline: 'Brak aktualnej dyscypliny',
      no_actual_discipline_tooltip: 'Brak aktualnego oświadczenia o dyscyplinach',
      no_actual_n: 'Brak aktualnego N',
      no_actual_n_tooltip: 'Brak aktualnego oświadczenia o zaliczeniu do liczby N',
      no_records: 'Niestety nie odnaleziono pracowników spełniających warunki wyszukiwania',
      number_n: 'Zaliczenia do N',
      publications: 'Osiągnięcia',
      remove_n_statement_confirmation: 'Czy na pewno chcesz usunąć oświadczenie?',
      remove_statement: 'Usuwanie oświadczenia',
      remove_statement_confirmation: 'Czy na pewno chcesz usunąć oświadczenie?\n\nUsunięcie oświadczeń spowoduje skasowanie dyscypliny oraz zgody na ewaluację we wszystkich osiągnięciach pracownika.\n\nPo ponownym dodaniu oświadczeń należy przypisać dyscyplinę oraz zgodę na ewaluację w odpowiednich osiągnięciach.\n\nPowyższe operacje są możliwe do wykonania indywidualnie z poziomu publikacji lub grupowo za pomocą akcji zbiorowych, dostępnych z poziomu listy osiągnięć.',
      subtitle: '',
      title: 'Oświadczenia'
    }
  },
  test: {
    title: 'Komponenty'
  },
  test2: {
    title: 'Bloki'
  },
  user_messages: {
    user_messages_index: {
      download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania komunikatów.',
      title: 'Komunikaty'
    }
  },
  users: {
    list: {
      add_user: 'Dodaj użytkownika',
      adding_user: 'Dodawanie użytkownika',
      delete_user: 'Usuń użytkownika',
      delete_user_confirm: 'Usuwanie użytkownika',
      delete_user_confirm_text: 'Czy na pewno chcesz usunąć użytkownika?',
      delete_user_success: 'Pomyślnie usunięto użytkownika',
      edit_roles: 'Edycja ról',
      edit_user: 'Edytuj użytkownika',
      edit_user_failure: 'Użytkownik nie został zedytowany',
      edit_user_success: 'Użytkownik został zedytowany pomyślnie',
      editing_user: 'Edytowanie użytkownika',
      email: 'Adres email',
      fields: {
        academy: 'Instancja',
        created_at: 'Data stworzenia konta',
        email: 'Adres email',
        full_name: 'Imię i nazwisko',
        last_login: 'Ostatni czas logowania',
        roles: 'Rola użytkownika'
      },
      filter_users: 'Filtruj użytkowników',
      first_name: 'Imię',
      no_records: 'Niestety nie odnaleziono użytkowników spełniających warunki wyszukiwania',
      no_role: 'Brak roli',
      role: 'Rola',
      roles: {
        analityknaukowy: 'Analityk naukowy',
        custom2: 'Custom2',
        custom3: 'Custom3',
        dziekan: 'Dziekan',
        editor: 'Edytor',
        kierownikdyscypliny: 'Kierownik dyscypliny',
        manager: 'Menadżer',
        naukowiec: 'Naukowiec',
        pracownik: 'Pracownik administracyjny',
        pracownikjednostki: 'Pracownik administracyjny wydziału',
        superkierownik: 'Super kierownik',
        useradmin: 'Administrator użytkowników',
        jednostka: 'Jednostka',
        dyscyplina: 'Dyscyplina',
        rektor: 'Rektor',
        audyt: 'Audyt'
      },
      subtitle: '',
      surname: 'Nazwisko',
      title: 'Użytkownicy',
      uid: 'Uid',
      user_success: 'Użytkownik został dodany pomyślnie',
      users_list: 'Lista użytkowników',
      wrong_user: 'Nie udało się dodać użytkownika'
    }
  },
  webpages: {
    webpages_edit: {
      save: 'Zapisz',
      save_and_publish: 'Zapisz i opublikuj',
      save_as_draft: 'Zapisz wersję roboczą',
      subtitle: 'Edycja strony',
      title: 'CMS',
      webpage_updated: 'Strona internetowa została pomyślnie zaktualizowana.'
    },
    webpages_new: {
      publish: 'Opublikuj',
      save: 'Zapisz',
      save_draft: 'Zapisz wersję roboczą',
      subtitle: 'Nowa strona',
      title: 'CMS'
    }
  },
  xml_files: {
    add_employee: 'Dodawanie pracowników',
    calc_points: 'Przeliczenie punktów',
    file_date: 'Data utworzenia',
    file_name: 'Nazwa pliku',
    list: {
      subtitle: '',
      title: 'Pliki PBN.xml'
    },
    new_file: 'Nowy plik',
    with_fees: 'Import z opłatami'
  },
  xls_files: {
    list: {
      subtitle: '',
      title: 'Pliki Xls importu pracowników'
    },
    new_file: 'Nowy plik',
    publications: {
      subtitle: '',
      title: 'Pliki Xls importu oświadczeń o osiągnięciach'
    }
  }
}
