export const general = {
  academy: 'Uczelnia',
  accept: 'Akceptuj',
  accuracy: 'trafność',
  action_cannot_be_reverted: 'Ta akcja nie może zostać cofnięta!',
  active: 'aktywne',
  add: 'Dodaj',
  add_attachment: 'Dodaj załącznik',
  all: 'Wszystkie',
  and: 'i',
  archive: 'Archiwizuj',
  are_you_sure: 'Jesteś pewien?',
  authors: 'Autorzy',
  avatar: 'Awatar',
  back: 'Powrót',
  calendar: 'Kalendarz',
  cancel: 'Anuluj',
  change: 'Zmień',
  change_contrast: 'Zmień kontrast',
  change_order: 'Zmień kolejność',
  change_to: 'zmień na',
  check_errors: 'Sprawdź błędy',
  clear: 'Wyczyść',
  clear_filters: 'Wyczyść filtry',
  collapse: 'Zwiń',
  confirm: 'Zatwierdź',
  content: 'Treść',
  copy: 'Kopiuj',
  currency: {
    pln: 'zł'
  },
  create_new: 'Stwórz nową',
  date_added: 'data dodania',
  date_of_publication: 'data publikacji',
  days_left: 'Został 1 dzień do końca | Zostało {count} dni do końca',
  decrease_font: 'Zmniejsz font',
  delete: 'Usuń',
  delete_message: 'Usuń wiadomość',
  deleted: 'Usunięty',
  dictionary_updated: 'Słownik został zaktualizowany pomyślnie',
  dictionary_word_updated: 'Słowo słownikowe zostało zaktualizowane pomyślnie',
  discard_changes: 'Odrzuć zmiany',
  discipline: 'Dyscyplina',
  download: 'Pobierz',
  duplicate: 'Duplikat',
  edit: 'Edytuj',
  enlarge_font: 'Zwiększ font',
  error_handling: 'Błąd obsługi',
  error_try_later: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
  exclamation_mark: 'Uwaga',
  expand: 'Rozwiń',
  fields_not_filled: 'Niektóre pola nie zostały poprawnie wypełnione. Sprawdź błędy i spróbuj ponownie.',
  forbidden: 'Nie masz uprawnień do wykonania tej czynności.',
  from: 'od',
  full_time: 'Pełny etat',
  gallery: 'Galeria',
  generate: 'Generuj',
  go_down: 'Do dołu',
  go_up: 'Do góry',
  hello_world: 'Siema Świecie!',
  hide: 'Ukryj',
  id: 'Identyfikator',
  in: 'w',
  information: 'Informacja',
  lack: 'brak',
  load: 'Wczytaj',
  lock: 'Blokuj',
  logged_out: 'Zostałeś wylogowany',
  main_menu: 'Menu główne',
  make_copy: 'Stwórz kopię',
  mark: 'Zaznacz',
  message_sent: 'Wiadomość została wysłana',
  message_type: {
    communique: 'Komunikat',
    newsletter: 'Newsletter',
    sms: 'SMS'
  },
  minuses: {
    m1: 'W przypadku gdy pracownik, który zadeklarował dyscyplinę w oświadczeniu o dyscyplinach oraz zalicza się do liczby N w tej dyscyplinie na podstawie oświadczenia o zaliczeniu do liczby N,  jednak w okresie objętym ewaluacją, nie upoważnił podmiotu do wykazania w ewaluacji w tej dyscyplinie żadnej publikacji z listy (czasopism, wydawców lub konferencji) opublikowanej w roku 2019 lub publikacji ocenionej pozytywnie przez ekspertów, liczba 3N pomniejszana jest o 3.',
    m2: 'W przypadku gdy pracownik zadeklarował dyscyplinę/dyscypliny w oświadczeniu o dyscyplinach jednak nie złożył oświadczenia o zaliczeniu do liczby N w żadnym z podmiotów, w których był zatrudniony w okresie ewaluacji (a był do tego zobowiązany), liczba 3N dla tej dyscypliny/dyscyplin zostaje pomniejszona o 6.',
    m3: 'W przypadku gdy pracownik nie złożył oświadczenia o dyscyplinach (a był do tego zobowiązany), liczba 3N każdej dyscypliny objętej ewaluacją pomniejszana jest o 6.'
  },
  new_message: 'Nowa wiadomość',
  no: 'nie',
  no_access: 'Brak dostępu',
  noname: 'Brak nazwy',
  not_applicable: 'Nie dotyczy',
  number: 'Numer',
  or: 'lub',
  page: 'Strona',
  pagination: 'Paginacja',
  per_page: 'Ilość pozycji na stronie',
  preview: 'Podgląd',
  preview_saved_draft: 'Podgląd zapisanej wersji roboczej',
  print: 'Drukuj',
  register: 'Zapisz się',
  reject: 'Odrzuć',
  reject_changes: 'Odrzuć zmiany',
  remove: 'Usuń',
  remove_from_favorites: 'Usuń z ulubionych',
  results_found: 'Znaleziono 0 wyników | Znaleziono 1 wynik | Znaleziono {count} wyniki | Znaleziono {count} wyników',
  roles: {
    manager: 'manager',
    naukowiec: 'naukowiec',
    pracownik: 'pracownik',
    pracownikjednostki: 'pracownik wydziału',
    kierownikdyscypliny: 'kierownik dyscypliny',
    analityknaukowy: 'analityk naukowy',
    editor: 'edytor',
    dziekan: 'dziekan',
    superkierownik: 'superkierownik',
    useradmin: 'administrator użytkowników',
    custom2: 'custom2',
    custom3: 'custom3',
    jednostka: 'jednostka',
    dyscyplina: 'dyscyplina',
    rektor: 'rektor',
    audyt: 'audyt'
  },
  save: 'Zapisz',
  save_changes: 'Zapisz zmiany',
  save_draft: 'Zapisz wersję roboczą',
  see_less: 'pokaż mniej',
  see_more: 'zobacz więcej',
  select: 'Wybierz',
  send: 'Wyślij',
  show_all: 'Pokaż całość',
  show_more: 'ZOBACZ WIĘCEJ',
  show_results: 'Pokaż wyniki',
  skip_to_content: ' Przejdź do treści',
  slots: 'Sloty',
  statements: 'Oświadczenia',
  status: 'Status',
  statuses: {
    accepted: 'Zaakceptowana',
    archived: 'Zarchiwizowana',
    draft: 'Wersja robocza',
    finished: 'Zakończona',
    for_acceptance: 'Oczekująca',
    recent: 'Nowa',
    rejected: 'Odrzucona',
    withdrawn: 'Wycofana'
  },
  update_scopus: 'Aktualizuj dane Scopus',
  wrong_password: 'Błędne hasło',
  wrong_combination_of_login: 'Niepoprawna kombinacja loginu i hasła',
  error: 'Błąd',
  no_rights: 'Brak uprawnień',
  steps: {
    next: 'Dalej',
    previous: 'Wstecz',
    publish: 'Zapisz i opublikuj',
    send_for_publication: 'Zapisz i wyślij do publikacji'
  },
  success: 'Sukces',
  suggest: 'Zaproponuj',
  text_section: 'Sekcja tekstowa',
  time_to_end: 'czas do końca',
  title: 'Tytuł',
  to: 'do',
  type: 'Rodzaj',
  unexpected_error: 'Wystąpił nieoczekiwany błąd.',
  unlock: 'Odblokuj',
  unregister: 'Wypisz się',
  update: 'Aktualizuj',
  update_application_text_1: 'Trwa aktualizacja serwisu. Na czas aktualizacji zablokowany jest dostęp do aplikacji.',
  update_application_text_2: 'Proces aktualizacji powinien zająć nie więcej niż 15 minut. Za wszystkie utrudnienia przepraszamy.',
  when: 'kiedy',
  where: 'gdzie',
  withdraw: 'Wycofaj',
  words: 'Słowa',
  yes: 'tak'
}
