<template>
    <div class="academy row">
      <b-col>
        <span class="name">{{academy.name}}</span>
      </b-col>
      <b-col cols="1" class="pub-type">
        <span v-if="hasRight('academy_details')" class="name">{{ academy.aid }}</span>
      </b-col>
      <b-col cols="1" class="pub-type">
        <span v-if="hasRight('academy_details')" class="name">{{ tf(academy.locked) }}</span>
      </b-col>
      <b-col cols="2" class="pub-type">
        <span v-if="hasRight('academy_details')" class="name">{{ tf(academy.editLanguage) }}</span>
      </b-col>
      <b-col cols="2" class="pub-type">
        <span v-if="hasRight('academy_details')" class="name">{{ tf(academy.cyclicalEvaluation) }}</span>
      </b-col>
      <b-col cols='2' class="buttons d-flex justify-content-end">
        <spinner v-if="recalcSlotsSpinner" class="my-spinner" style="width: 38px; padding: 0 12px;"/>
        <a href="javascript:;" :aria-label="$t('general.edit')" style="display: inline-block" class="my-pen button-element" :style="[(hasRight('edit_academy') || hasRight('own_academy_cleaning')) ? { 'margin-right': '1.3rem' } : { 'margin-right': '0' } ]" @click="edit" v-if="!recalcSlotsSpinner && (hasRight('edit_academy') || hasRight('edit_own_academy'))">
          <i class="fas fa-pen academy-icon" :id="`edit-academy` + academy.id"></i>
          <b-tooltip custom-class="my-tooltip" :target="`edit-academy` + academy.id">
            {{ $t('general.edit') }}
          </b-tooltip>
        </a>
        <spinner v-if="clearSpinner" class="text-danger" style="width: 38px; padding: 0 12px;"/>
        <a href="javascript:;" :aria-label="$t('general.clear')" class="text-danger button-element" style="display: inline-block" @click="clearModal" v-if="!clearSpinner && (hasRight('edit_academy') || hasRight('own_academy_cleaning'))">
          <i class="fas fa-eraser academy-icon" :id="`delete-academy` + academy.id"></i>
          <b-tooltip :target="`delete-academy` + academy.id">
            {{ $t('general.clear') }}
          </b-tooltip>
        </a>
      </b-col>
      <b-modal ref="academyModal"
               :hide-header="true"
               :centered="true"
               footer-class="my-modal-footer"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="academySave"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               v-on-clickaway = "escModal"
               content-class="my-modal-content"
      >
        <pcg-text-input class="my-input" v-model="rAcademy.name" :label="$t('views.instances.list.name')" :showLabel="true"/>
        <pcg-text-input class="my-input" v-model="rAcademy.aid" type="number" label="OpenID" :showLabel="true"/>
        <div class="my-input">
          <div class="pcg-input-label">{{ $t('views.users.list.role') }}</div>
          <pcg-select
              class="modal-select"
              :include-blank="true"
              :options="tDictionary(validRolesOptions)"
              :default-option="{ text: $t('general.select'), id: '' }"
              v-model="rAcademy.firstRole"
          />
        </div>
        <pcg-text-input class="my-input" v-model="rAcademy.pbnId" label="PBN Id" :showLabel="true"/>
        <pcg-text-input class="my-input" v-model="rAcademy.polonAppId" label="Polon App-Id" :showLabel="true"/>
        <pcg-text-input class="my-input" v-model="rAcademy.polonAppToken" label="Polon App-Token" :showLabel="true"/>
        <pcg-text-input class="my-input" v-model="rAcademy.polonInstitution" :label="$t('views.instances.list.polon_institution')" :showLabel="true"/>
        <pcg-checkbox class="my-input" v-model="rAcademy.locked" showLabel="true" >
          {{ $t('views.instances.list.locked') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.cyclicalEvaluation" showLabel="true" >
          {{ $t('views.instances.list.cyclical_evaluation') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.editLanguage" showLabel="true" >
          {{ $t('views.instances.list.change_language') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.simulateEmployment" showLabel="true" >
          {{ $t('views.instances.list.simulate_employment') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.blockSetDiscipline" showLabel="true" >
          {{ $t('views.instances.list.block_set_discipline') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.updateRedaction" showLabel="true" >
          {{ $t('views.instances.list.update_redaction') }}
        </pcg-checkbox>
        <div class="pcg-input-label">{{ $t('views.instances.list.default_evaluation') }}</div>
        <pcg-select
            class="my-select-short"
            style="display: inline-block"
            :options="availableEvaluations"
            :default-option="{ text: $t('general.select'), id: 1 }"
            v-model="rAcademy.defaultEvaluation"
        />
        <pcg-checkbox class="my-input" v-model="rAcademy.defaultYearFilter" showLabel="true" >
          {{ $t('views.instances.list.default_year_filter') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.copyConference" showLabel="true" >
          {{ $t('views.instances.list.copy_conference') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.exportOnlyNew" showLabel="true" >
          {{ $t('views.instances.list.export_only_new') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.pbnDailyUpdate" showLabel="true" >
          {{ $t('views.instances.list.pbn_cyclical_update') }}
        </pcg-checkbox>
        <template v-if="rAcademy.pbnDailyUpdate">
          <div class="pcg-input-label">{{ $t('views.instances.list.update_frequency') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateTypes)"
              :default-option="{ text: $t('general.select'), id: 'daily' }"
              v-model="rAcademy.pbnUpdateType"
          />
          <div class="pcg-input-label">{{ $t('views.import_polon.import_type') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateModes)"
              :default-option="{ text: $t('general.select'), id: 'only_new' }"
              v-model="rAcademy.pbnUpdateMode"
          />
          <pcg-checkbox class="col-12"
                        v-model="rAcademy.pbnSetDiscipline">
            {{$t('views.import_pbn_api.with_discipline')}}
          </pcg-checkbox>
          <pcg-checkbox class="col-12"
                        v-model="rAcademy.pbnAddEmployee">
            {{$t('views.xml_files.add_employee')}}
          </pcg-checkbox>
          <pcg-checkbox class="col-12"
                        v-model="rAcademy.pbnCalcPoints">
            {{$t('views.xml_files.calc_points')}}
          </pcg-checkbox>
          <pcg-input-number class="my-input" v-model="rAcademy.pbnYearFrom" :label="$t('views.import_pbn_api.year_from')" :showLabel="true"/>
          <pcg-input-number class="my-input" v-model="rAcademy.pbnYearTo" :label="$t('views.import_pbn_api.year_to')" :showLabel="true"/>
        </template>
        <pcg-checkbox class="my-input" v-model="rAcademy.polonDailyUpdate" showLabel="true" >
          {{ $t('views.instances.list.polon_cyclical_update') }}
        </pcg-checkbox>
        <template v-if="rAcademy.polonDailyUpdate">
          <div class="pcg-input-label">{{ $t('views.instances.list.update_frequency') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateTypes)"
              :default-option="{ text: $t('general.select'), id: 'daily' }"
              v-model="rAcademy.polonUpdateType"
          />
          <pcg-checkbox class="my-input" v-model="rAcademy.polonArt" showLabel="true" >
            {{ $t('views.instances.list.polon_art') }}
          </pcg-checkbox>
          <pcg-checkbox class="my-input" v-model="rAcademy.polonPatent" showLabel="true" >
            {{ $t('views.instances.list.polon_patent') }}
          </pcg-checkbox>
          <pcg-checkbox class="my-input" v-model="rAcademy.polonProject" showLabel="true" >
            {{ $t('views.instances.list.polon_project') }}
          </pcg-checkbox>
          <pcg-checkbox class="my-input" v-model="rAcademy.polonService" showLabel="true" >
            {{ $t('views.instances.list.polon_service') }}
          </pcg-checkbox>
          <pcg-checkbox class="my-input" v-model="rAcademy.polonCommercialization" showLabel="true" >
            {{ $t('views.instances.list.polon_commercialization') }}
          </pcg-checkbox>
          <div class="pcg-input-label">{{ $t('views.import_polon.import_type') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateModes)"
              :default-option="{ text: $t('general.select'), id: 'only_new' }"
              v-model="rAcademy.polonUpdateMode"
          />
          <pcg-checkbox class="col-12"
                        v-model="rAcademy.polonAddEmployee">
            {{$t('views.xml_files.add_employee')}}
          </pcg-checkbox>
          <div v-if="rAcademy.polonAddEmployee" class="pcg-input-label">{{ $t('views.import_polon_employees.data_type') }}</div>
          <pcg-select
              v-if="rAcademy.polonAddEmployee"
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(dataTypes)"
              :default-option="{ text: $t('general.select'), id: '' }"
              v-model="rAcademy.polonAchievementEmployeeDataType"
          />
        </template>
        <pcg-checkbox class="my-input" v-model="rAcademy.polonDailyEmployeeUpdate" showLabel="true" >
          {{ $t('views.instances.list.polon_employee_cyclical_update') }}
        </pcg-checkbox>
        <template v-if="rAcademy.polonDailyEmployeeUpdate">
          <div class="pcg-input-label">{{ $t('views.instances.list.update_frequency') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateEmployeeTypes)"
              :default-option="{ text: $t('general.select'), id: 'daily' }"
              v-model="rAcademy.polonEmployeeUpdateType"
          />
          <div class="pcg-input-label">{{ $t('views.import_polon.import_type') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateModes)"
              :default-option="{ text: $t('general.select'), id: 'only_new' }"
              v-model="rAcademy.polonEmployeeUpdateMode"
          />
          <div class="pcg-input-label">{{ $t('views.import_polon_employees.data_type') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(dataTypes)"
              :default-option="{ text: $t('general.select'), id: '' }"
              v-model="rAcademy.polonEmployeeDataType"
          />
        </template>
        <pcg-checkbox class="my-input" v-model="rAcademy.polonDailyPhdStudentUpdate" showLabel="true" >
          {{ $t('views.instances.list.polon_phd_student_cyclical_update') }}
        </pcg-checkbox>
        <template v-if="rAcademy.polonDailyPhdStudentUpdate">
          <div class="pcg-input-label">{{ $t('views.instances.list.update_frequency') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateTypes)"
              :default-option="{ text: $t('general.select'), id: 'daily' }"
              v-model="rAcademy.polonPhdStudentUpdateType"
          />
          <div class="pcg-input-label">{{ $t('views.import_polon.import_type') }}</div>
          <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="tDictionary(updateModes)"
              :default-option="{ text: $t('general.select'), id: 'only_new' }"
              v-model="rAcademy.polonPhdStudentUpdateMode"
          />
        </template>
        <pcg-checkbox class="my-input" v-model="rAcademy.searchByName" showLabel="true" >
          {{ $t('views.instances.list.search_by_name') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.apiSetDiscipline" showLabel="true" >
          {{ $t('views.instances.list.api_set_discipline') }}
        </pcg-checkbox>
        <pcg-checkbox class="my-input" v-model="rAcademy.exportFees" showLabel="true" >
          {{ $t('views.instances.list.export_fees') }}
        </pcg-checkbox>
      </b-modal>
      <b-modal ref="clearModal"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="clear"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.instances.list.base_cleaning') }}</div>
        <div class="confirm-text">{{ $t('views.instances.list.base_cleaning_confirmation') }}</div>
      </b-modal>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import translateDictionary from '../mixins/translate_dictionary'
import ApiAcademies from '../api/academies'
import Spinner from './Spinner'
import hasRight from '../mixins/has_right'
import tf from '../mixins/tf'
import { mapGetters } from 'vuex'
import waitForJobs from '../mixins/wait_for_jobs'
import unexpectedError from '../mixins/unexpected_error'

// Komponent pojedynczej uczelni - edycja, czyszczenie uczelni
// @group Uczelnie
export default {
  name: 'AcademyElement',
  components: { Spinner },
  mixins: [clickaway, hasRight, translateDictionary, tf, waitForJobs, unexpectedError],
  data: function () {
    return {
      clearSpinner: false,
      recalcSlotsSpinner: false,
      rAcademy: {
        aid: null,
        defaultEvaluation: 1,
        name: null,
        pbnId: null,
        polonAppId: null,
        polonAppToken: null,
        firstRole: null,
        editLanguage: null,
        pbnDailyUpdate: false,
        pbnUpdateMode: 'only_new',
        pbnSetDiscipline: false,
        pbnAddEmployee: false,
        pbnCalcPoints: false,
        pbnYearFrom: null,
        pbnYearTo: null,
        pbnUpdateType: 'daily',
        polonDailyUpdate: false,
        polonUpdateMode: 'only_new',
        polonAddEmployee: false,
        polonUpdateType: 'daily',
        polonAchievementEmployeeDataType: 'basic',
        polonInstitution: null,
        searchByName: false,
        defaultYearFilter: false,
        exportOnlyNew: false,
        simulateEmployment: false,
        copyConference: false,
        apiSetDiscipline: false,
        exportFees: false,
        blockSetDiscipline: false,
        updateRedaction: false,
        polonDailyEmployeeUpdate: false,
        polonEmployeeUpdateMode: 'only_new',
        polonEmployeeUpdateType: 'daily',
        polonEmployeeDataType: 'all',
        polonDailyPhdStudentUpdate: false,
        polonPhdStudentUpdateMode: 'only_new',
        polonPhdStudentUpdateType: 'daily',
        polonArt: false,
        polonPatent: false,
        polonProject: false,
        polonService: false,
        polonCommercialization: false
      },
      updateModes: [
        { text: 'Tylko nowe', text_en: 'Only new', id: 'only_new' },
        { text: 'Aktualizacja', text_en: 'Update', id: 'only_update' },
        { text: 'Nowe i aktualizacja', text_en: 'New and update', id: 'new_and_update' }
      ],
      updateTypes: [
        { text: 'Codzienne', text_en: 'Daily', id: 'daily' },
        { text: 'Tygodniowe', text_en: 'Weekly', id: 'weekly' }
      ],
      updateEmployeeTypes: [
        { text: 'Codzienne', text_en: 'Daily', id: 'daily' },
        { text: 'Codzienne połówkowe', text_en: 'Daily half', id: 'daily_half' },
        { text: 'Tygodniowe', text_en: 'Weekly', id: 'weekly' }
      ],
      dataTypes: [
        { text: 'Podstawowe dane', text_en: 'Basic data', id: 'basic' },
        { text: 'Podstawowe dane + zatrudnienie i oświadczenia', text_en: 'Basic data + employment and statements', id: 'all' }
      ],
      validRolesOptions: [],
      rolesOptions: []
    }
  },
  props: {
    // Academy object, zawierający informacje o pojedynczej uczelni. (ID, OpenID, Nazwa uczelni, Polon App-Id, Polon App-Token, Blokada, Cykliczna ewaluacja)
    academy: Object,
    validRoles: Array,
    availableEvaluations: Array
  },
  beforeMount () {
    if (this.$store.getters['period/years'].length === 0) {
      this.$store.dispatch('period/setInitialYears')
    }
  },
  computed: {
    ...mapGetters('period', ['allYears']),
    years () {
      return this.allYears
    }
  },
  watch: {
    years: {
      deep: true,
      handler: function (value) {
        if (!this.rAcademy.pbnYearTo || !this.rAcademy.pbnYearFrom) {
          this.rAcademy.pbnYearFrom = Math.min(...this.years)
          this.rAcademy.pbnYearTo = Math.max(...this.years)
        }
      }
    }
  },
  mounted: function () {
    for (const key in this.academy) {
      this.rAcademy[key] = this.academy[key]
    }
    this.validRoles.forEach(role => {
      switch (role) {
        case 'analityknaukowy':
          this.rolesOptions.push({ text: 'Analityk naukowy', text_en: 'Scientific analyst', id: 'analityknaukowy' })
          this.validRolesOptions.push({ text: 'Analityk naukowy', text_en: 'Scientific analyst', id: 'analityknaukowy' })
          break
        case 'useradmin':
          this.rolesOptions.push({ text: 'Administrator użytkowników', text_en: 'User administrator', id: 'useradmin' })
          this.validRolesOptions.push({ text: 'Administrator użytkowników', text_en: 'User administrator', id: 'useradmin' })
          break
        case 'custom2':
          this.rolesOptions.push({ text: 'Custom2', text_en: 'Custom2', id: 'custom2' })
          this.validRolesOptions.push({ text: 'Custom2', text_en: 'Custom2', id: 'custom2' })
          break
        case 'custom3':
          this.rolesOptions.push({ text: 'Custom3', text_en: 'Custom3', id: 'custom3' })
          this.validRolesOptions.push({ text: 'Custom3', text_en: 'Custom3', id: 'custom3' })
          break
        case 'dziekan':
          this.rolesOptions.push({ text: 'Dziekan', text_en: 'Dziekan', id: 'dziekan' })
          this.validRolesOptions.push({ text: 'Dziekan', text_en: 'Dziekan', id: 'dziekan' })
          break
        case 'editor':
          this.rolesOptions.push({ text: 'Edytor', text_en: 'Editor', id: 'editor' })
          this.validRolesOptions.push({ text: 'Edytor', text_en: 'Editor', id: 'editor' })
          break
        case 'kierownikdyscypliny':
          this.rolesOptions.push({ text: 'Kierownik dyscypliny', text_en: 'Discipline manager', id: 'kierownikdyscypliny' })
          this.validRolesOptions.push({ text: 'Kierownik dyscypliny', text_en: 'Discipline manager', id: 'kierownikdyscypliny' })
          break
        case 'manager':
          this.rolesOptions.push({ text: 'Menadżer', text_en: 'Manager', id: 'manager' })
          this.validRolesOptions.push({ text: 'Menadżer', text_en: 'Manager', id: 'manager' })
          break
        case 'naukowiec':
          this.rolesOptions.push({ text: 'Naukowiec', text_en: 'Scientist', id: 'naukowiec' })
          this.validRolesOptions.push({ text: 'Naukowiec', text_en: 'Scientist', id: 'naukowiec' })
          break
        case 'pracownik':
          this.rolesOptions.push({ text: 'Pracownik administracyjny', text_en: 'Administrative worker', id: 'pracownik' })
          this.validRolesOptions.push({ text: 'Pracownik administracyjny', text_en: 'Administrative worker', id: 'pracownik' })
          break
        case 'superkierownik':
          this.rolesOptions.push({ text: 'Superkierownik', text_en: 'Superkierownik', id: 'superkierownik' })
          this.validRolesOptions.push({ text: 'Superkierownik', text_en: 'Superkierownik', id: 'superkierownik' })
          break
        case 'jednostka':
          this.rolesOptions.push({ text: 'Jednostka', text_en: 'Faculty', id: 'jednostka' })
          this.validRolesOptions.push({ text: 'Jednostka', text_en: 'Faculty', id: 'jednostka' })
          break
        case 'dyscyplina':
          this.rolesOptions.push({ text: 'Dyscyplina', text_en: 'Discipline', id: 'dyscyplina' })
          this.validRolesOptions.push({ text: 'Dyscyplina', text_en: 'Discipline', id: 'dyscyplina' })
          break
        case 'rektor':
          this.rolesOptions.push({ text: 'Rektor', text_en: 'Rector', id: 'rektor' })
          this.validRolesOptions.push({ text: 'Rektor', text_en: 'Rector', id: 'rektor' })
          break
        case 'audyt':
          this.rolesOptions.push({ text: 'Audyt', text_en: 'Audit', id: 'audyt' })
          this.validRolesOptions.push({ text: 'Audyt', text_en: 'Audit', id: 'audyt' })
          break
        default:
          this.rolesOptions.push({ text: role, text_en: role, id: role })
          this.validRolesOptions.push({ text: role, text_en: role, id: role })
      }
    })
    this.rolesOptions.unshift({ text: 'Brak roli', text_en: 'No role', id: 'no_role' })
    this.rolesOptions.unshift({ text: 'Wybierz', text_en: 'Choose', id: '' })
  },
  methods: {
    /**
     * @vuese
     * Metoda służąca do czyszczenia uczelni
     * @arg Brak parametrow
     */
    clear () {
      this.clearSpinner = true
      ApiAcademies.clearAcademy(this.academy)
        .then(result => {
          this.clearSpinner = false
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.clearSpinner = false
        })
    },
    /**
     * @vuese
     * Metoda uruchamiająca modal służący do potwierdzenia czyszczenia uczelni
     * @arg Brak parametrow
     */
    clearModal () {
      this.$refs.clearModal.show()
    },
    /**
     * @vuese
     * Metoda uruchamiająca modal służący do edycji uczelni
     * @arg Brak parametrow
     */
    edit () {
      for (const key in this.academy) {
        this.rAcademy[key] = this.academy[key]
      }
      this.$refs.academyModal.show()
    },
    /**
     * @vuese
     * Metoda chowająca modal edycji uczelni
     * @arg Brak parametrow
     */
    escModal () {
      this.$refs.academyModal.hide()
    },
    /**
     * @vuese
     * Metoda aktualizująca dane uczelni. Kontroler - AcademiesController#update
     * @arg OpenID, Nazwa uczelni, Polon App-Id, Polon App-Token, Blokada, Cykliczna ewaluacja
     */
    academySave () {
      ApiAcademies.updateAcademy(this.rAcademy)
        .then(result => {
          this.$refs.academyModal.hide()
          for (const key in this.academy) {
            this.academy[key] = this.rAcademy[key]
          }
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.recalcSlotsSpinner = true
            this.waitForJob(jobId, this.recalcSlotsUpdated, this.recalcSlotsError)
          }
        })
        .catch(err => {
          this.recalcSlotsSpinner = false
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
        })
    },
    recalcSlotsError () {
      this.recalcSlotsSpinner = false
      this.unexpectedError()
    },
    recalcSlotsUpdated () {
      this.recalcSlotsSpinner = false
      this.$toastr.s(this.$t('views.instances.list.recalculated_slots'))
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';

    .name {
        color: $pcg-gray;
        font-weight: 400;
    }

    .my-input {
        /deep/ .pcg-input {
            height: calc(1.5rem + 0.75rem + 2px)
        }
        /deep/ .pcg-input-label {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
    }

    .my-select-short {
      margin-right: .8rem;
      margin-left: .7rem;
      width: 200px;
      position: relative;
      display: inline-block;
      /deep/ .bootstrap-select .dropdown-toggle {
        height: calc(1.5em + 0.75em + 2px);
        color: $pcg-gray;
      }
      /deep/ .filter-option-inner {
        position: relative;
        bottom: .45rem;
      }
    }

    .clicked {
        cursor: pointer;
        font-weight: 400;
    }

    .confirm-header {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .confirm-text {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1rem;
    }

    .my-pen {
      color: $main-active-color;
    }

    .academy-icon {
      padding: 12px;
      border-radius: 50%;
      background-color: #ecf9fd;
    }

    .button-element {
      position: relative;
      bottom: .7rem;
      cursor: pointer;
    }

    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      font-weight: 400;
      padding: .5rem;
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray-background !important;
    }

    .my-spinner {
      color: $main-active-color;
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .name {
            color: $pcg-gray;
        }
        .my-input {
            /deep/ .pcg-input-label {
                color: $pcg-gray;
            }
        }
        .confirm-header {
            color: $pcg-gray;
        }
        .confirm-text {
            color: $pcg-gray;
        }
      .my-pen {
        color: $main-active-color;
      }
      .academy-icon {
        background-color: #003204;
      }
      /deep/ .tooltip-inner {
        background: $pcg-white;
        color: $pcg-dark-gray;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
        border-color: $pcg-light-gray;
      }
      /deep/ .arrow::before {
        border-top-color: $pcg-light-gray !important;
      }
      .my-spinner {
        color: $main-active-color;
      }
    }
</style>
